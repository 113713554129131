import { Fragment } from "react";
import { useForgotPassword } from "../Hooks/ForgotPassword";
import Spinner from "../Spinner/Spinner";



const ForgotPassword = () => {
  const {
    formField,
    isEmail,
    isLoading,
    error,
    successMsg,
    handleChange,
    handleRequestPassword,
    handleResetPassword,
  } = useForgotPassword();

  return (
    <Fragment>
      <div className="auth-container">
        {isEmail ? (
          <form onSubmit={handleRequestPassword}>
            <h4>Please enter your Email to reset your password</h4>
            <div className="name-card">
              <label>
                Email
                <input
                  type="email"
                  name="email"
                  value={formField.email}
                  onChange={handleChange}
                  required
                />
              </label>
            </div>
            {error && <p>{error}</p>}
            <button>{isLoading ? <Spinner /> : "Submit"}</button>
          </form>
        ) : (
          <div className="auth-container">
            <h4>Password Reset OTP Sent Successfully, please check your mail</h4>
            <form onSubmit={handleResetPassword}>
              <div className="name-card">
                <label>
                  Reset code
                  <input
                    type="number"
                    name="token"
                    value={formField.token}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  New Password
                  <input
                    type="password"
                    name="password"
                    value={formField.password}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              {error && <p>{error}</p>}
              <button>{isLoading ? <Spinner /> : "Reset Password"}</button>
              {successMsg && <p>{successMsg}</p>}
            </form>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ForgotPassword;
