import React, { useState, useContext, Fragment } from "react";
import { AuthContext } from "../Context/context";
import { useQuery, useMutation } from "react-query";
import ConfirmationOverlay from "./ConfirmationOverlay"; 
import "../Css/Driveradmin.css";

const formatCurrency = (amountInKobo) => {
  const amountInNaira = amountInKobo / 100; // Convert from kobo to naira
  return amountInNaira.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
  });
};

const UpdateDispatch = () => {
  const { token } = useContext(AuthContext);
  const [selectedDispatch, setSelectedDispatch] = useState(null);
  const [cityInput, setCityInput] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [selectStatus, setSelectStatus] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(null);
  const [statusOverlay, setStatusOverlay] = useState(false);
  const [confirmationOverlay, setConfirmationOverlay] = useState(false);
  const [selectedConfirmationStatus, setSelectedConfirmationStatus] = useState(null);

  const handleSelectStatus = () => {
    setSelectStatus(!selectStatus);
  };

  const handleConfirmation = (status) => {
    setSelectedConfirmationStatus(status);
    setConfirmationOverlay(true);
  };

  const handleConfirmationResponse = (confirmed) => {
    setConfirmationOverlay(false);

    if (confirmed) {
      // Proceed with the update
      setLoadingStatus(selectedConfirmationStatus);
      updateDispatchStatusMutation.mutate(selectedConfirmationStatus);
    } else {
      // Do something when the user cancels the update
      // For example, set overlay to null
      setStatusOverlay(null);
    }
  };

  // Fetch states query
  const { data: statesData, isLoading: statesLoading, isError: statesError } = useQuery(
    "states",
    async () => {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/States`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      return data.results;
    }
  );

  const { data: dispatches, isLoading: dispatchesLoading, isError: dispatchesError } = useQuery(
    "dispatches",
    async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Driver/Fetch/Dispatches`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (!res.ok) {
          throw new Error(resData.message);
        }
        return resData.results;
      } catch (error) {
        console.error("Error fetching dispatches", error);
        throw error;
      }
    },
    {
      refetchInterval: 1000,
    }
  );

  // Update dispatch status mutation
  const updateDispatchStatusMutation = useMutation(
    (status) => {
      if (!selectedDispatch || !selectedState || !cityInput) return;

      const { identifier: dispatchIdentifier } = selectedDispatch;

      return fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Driver/Update/DispatchStatus/${dispatchIdentifier}/${status}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            state_identifier: selectedState,
            city: cityInput,
          }),
        }
      ).then(async (res) => {
        const resData = await res.json();

        if (!res.ok) {
          throw new Error(resData.message);
        }

        setSelectStatus(false);

        return resData;
      });
    },
    {
      onSuccess: (data) => {
        setLoadingStatus(null);

        setSuccess(data.message);
        setTimeout(() => {
          setSuccess(false);
          setSelectedDispatch(false)
        }, 3000);
        setError(null);
      },
      onError: (error) => {
        setError(error.message);
        setLoadingStatus(null);
      },
    }
  );

  return (
    <Fragment>
      {dispatchesLoading ? <p>Loading dispatches...</p> : null}
      {dispatchesError ? <p>Error loading dispatches: {dispatchesError.message}</p> : null}

      {dispatches && dispatches.length > 0 && (
        <div className="update-dispatch-container">
          <div className="update-dispatch-content">
            {error && <p className="error-message">{error}</p>}
            <div className="dispatch-list">
              {dispatches.map((dispatchItem) => (
                <div key={dispatchItem.identifier} className="dispatch-info">
                  <div onClick={() => setSelectedDispatch(dispatchItem)}>
                    <span>
                      <h2>T{dispatchItem.identifier.slice(0, 12).toUpperCase()}</h2>
                      <p>
                        Name:{" "}
                        {dispatchItem.package.shipping_information?.receiver?.name || "N/A"}
                      </p>
                      <p>Package Name: {dispatchItem.package.name}</p>
                      <p>
                        Category: {dispatchItem.package.category.description}
                      </p>
                      <p>
                        Address:{" "}
                        {dispatchItem.package?.shipping_information?.receiver?.address_1 || "N/A"}
                      </p>
                    </span>
                    <span>
                      <p>
                        Shipping Cost:{" "}
                       {formatCurrency(dispatchItem.package.shipping_cost)}
                      </p>
                      <p>Insurance Type: {dispatchItem.package.insurance_type}</p>
                      <p>Package Quantity: {dispatchItem.package.quantity}</p>
                      <p>Status: {dispatchItem.status}</p>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {selectedDispatch && (
        <div className="overlay-dispatch">
          <div className="overley-header">
            <span>
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => setSelectedDispatch(null)}
              ></i>
              <h2>T{selectedDispatch.identifier.slice(0, 12).toUpperCase()}</h2>
            </span>
            <h2>Processing</h2>
          </div>
          <div className="overlay-content">
            <div className="header">
              <p>RECEIVER'S INFORMATION</p>
            </div>
            <div className="receiver-info">
              <span>
                <h3>Full Name</h3>
                <p>
                  {selectedDispatch?.package?.shipping_information?.receiver?.name || "N/A"}
                </p>
              </span>
              <span>
                <p>Phone Number</p>
                <h3>
                  {selectedDispatch?.package?.shipping_information?.receiver?.phone_number || "N/A"}
                </h3>
              </span>
              <span>
                <h3>Address</h3>
                <p>
                  {selectedDispatch?.package?.shipping_information?.receiver?.address_1}
                  ,{" "}
                  {selectedDispatch?.package?.shipping_information?.receiver?.address_2 || "N/A"}
                </p>
              </span>
              <span>
                <h3>Zip Code</h3>
                <p>
                  {selectedDispatch?.package?.shipping_information?.receiver?.zip_code || "N/A"}
                </p>
              </span>
              <span>
                <h3>State</h3>
                <p>
                  {selectedDispatch?.package?.shipping_information?.receiver?.state?.state_label || "N/A"}
                </p>
              </span>
            </div>

            <div className="packs-items">
              <div className="packs-header">
                <p>PACKAGE INFORMATION</p>
              </div>
              <div className="packs-item-wrapp">
                <span>
                  <h3>Weight</h3>
                  
                    <p>{selectedDispatch.package.weight}kg</p>
                  </span>
              
                <span>
                  <h3>Unit</h3>
                  <p>{selectedDispatch.package.quantity}</p>
                </span>
                <span>
                  <h3>Description</h3>
                  <p>{selectedDispatch.package.category.description}</p>
                </span>
                <span>
                  <h3>Shipping Fee</h3>
                  <p>
                    {formatCurrency(selectedDispatch.package.shipping_cost)}
                  </p>
                </span>
                <span>
                  <h3>Insurance</h3>
                  <p>{selectedDispatch.package.insurance_type}</p>
                </span>
                <span>
                  <h3>Status</h3>
                  <p>{selectedDispatch.status}</p>
                </span>
                <span>
                  <h3>Estimated Delivery Date</h3>
                  <p>
                    {selectedDispatch?.package?.shipping_information?.estimated_delivery_date}
                  </p>
                </span>
              </div>
            </div>

            <div className="update-dis-package">
              <p>UPDATE PACKAGE STATUS</p>
            </div>

            <div className="update-dis-wrap">
              <label>
                Select State
                <select
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                >
                  <option value="">State</option>
                  {statesLoading ? (
                    <option value="" disabled>
                      Loading...
                    </option>
                  ) : statesError ? (
                    <option value="" disabled>
                      Error loading states
                    </option>
                  ) : (
                    statesData.map((state) => (
                      <option key={state.identifier} value={state.identifier}>
                        {state.state_label}
                      </option>
                    ))
                  )}
                </select>
              </label>

              <label>
                City
                <input
                  type="text"
                  value={cityInput}
                  onChange={(e) => setCityInput(e.target.value)}
                  placeholder="Enter Current City"
                />
              </label>

              <label>
                Status
                <span onClick={handleSelectStatus}>
                  <p>Select Status</p>
                  <i className="fa-solid fa-caret-down"></i>
                </span>
              </label>

              {success && <p style={{ color: "green" }}>{success}</p>}
              {error && <p style={{ color: "red" }}>{error}</p>}
            </div>

            {selectStatus && (
              <div className="update-btn">
                <button onClick={() => handleConfirmation("InTransit")}>
                  {loadingStatus === "InTransit" ? "Updating..." : "In-Transit"}
                </button>
                <button onClick={() => handleConfirmation("Retrying")}>
                  {loadingStatus === "Retrying" ? "Updating..." : "Delayed"}
                </button>
                <button onClick={() => handleConfirmation("Failed")}>
                  {loadingStatus === "Failed" ? "Updating..." : "Diverted"}
                </button>
                <button onClick={() => handleConfirmation("Delivered")}>
                  {loadingStatus === "Delivered" ? "Updating..." : "Delivered"}
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {confirmationOverlay && (
        <ConfirmationOverlay
          message={`Are you sure you want to update this package as ${selectedConfirmationStatus}?`}
          onConfirm={() => handleConfirmationResponse(true)}
          onCancel={() => handleConfirmationResponse(false)}
        />
      )}
    </Fragment>
  );
};

export default UpdateDispatch;

