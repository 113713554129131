import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/Pages.css";

const Faqs = () => {
  const navigate = useNavigate();

  const [answersVisible, setAnswersVisible] = useState({
    service: false,
    deliveryTime: false,
    tracking: false,
    damageOrLost: false,
    packing: false,
  });

  const toggleAnswer = (key) => {
    setAnswersVisible((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <div className="faqs">
      <h1>FREQUENTLY ASKED QUESTIONS AND ANSWERS</h1>
      <p>What is on your mind about us</p>
      <div className="faqs-card">
        <div className="faqs-ul">
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.service ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("service")}
              ></i>{" "}
              <p>What services do you offer?</p>
            </span>
            {answersVisible.service && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.deliveryTime ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("deliveryTime")}
              ></i>{" "}
              <p>How long does it take to deliver my goods?</p>
            </span>
            {answersVisible.deliveryTime && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.tracking ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("tracking")}
              ></i>{" "}
              <p>Do you offer tracking and tracing?</p>
            </span>
            {answersVisible.tracking && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.damageOrLost ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("damageOrLost")}
              ></i>{" "}
              <p>What happens if my shipment is damaged or lost?</p>
            </span>
            {answersVisible.damageOrLost && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.packing ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("packing")}
              ></i>{" "}
              <p>Can you help me pack my goods for shipping?</p>
            </span>
            {answersVisible.packing && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
        </div>
      </div>
      <button onClick={() => navigate("/AllFaqs")}>See all Faq</button>
    </div>
  );
};

export default Faqs;
