// import { Fragment, useEffect, useContext } from "react";
// import { Routes, Route, useLocation, Navigate } from "react-router-dom";
// import Navbar from "./Navigation/Navbar";
// import Home from "./Routes/Home";
// import AboutUs from "./Routes/About-Us.jsx";
// import OurOffices from "./Routes/Our-Offices";
// import GetQuotes from "./Routes/Get-Qoutes";
// import ContactUs from "./Routes/Contact-us.jsx";
// import Footer from "./Pages/Footer";
// import Services from "./Routes/Services";
// import Login from "./Auth/Login";
// import ForgotPassword from "./Auth/Forgotpassword";
// import { AuthContext } from "./Context/context";
// import ShippingAddress from "./Logistics/ShippingAddress";
// import ItemsInfo from "./Logistics/ItemsInfo";
// import Processing from "./Logistics/Processing";
// import SupperAdmin from "./SupperAdmin/SupperAdmin";
// import InventoryAdmin from "./InventoryAdmin/InventoryAdmin";
// import DriverAdmin from "./DriverAdmin/DriverAdmin";
// import NotFound from "./notFound";
// import Settings from "./Pages/Settings";
// import Driver from "./SupperAdmin/Driver";
// import Profile from "./SupperAdmin/Profile.jsx";
// import Logistics from "./SupperAdmin/Logistic";
// import AddOfficers from "./SupperAdmin/Add-Officers";
// import AddDrivers from "./SupperAdmin/Add-driver";
// import FullInfoDriver from "./SupperAdmin/FullInfoDriver";
// import DriverHome from "./DriverAdmin/Driverhome";
// import AddAdmin from "./SupperAdmin/Add-addmin";
// import InventoryDriver from "./InventoryAdmin/InventoryDriver";
// import CreatePackages from "./InventoryAdmin/CreatePackage";
// import PackageDetails from "./InventoryAdmin/ PackageDetails";
// import CreateCategory from "./InventoryAdmin/CreateCategory";
// import DispatchDetails from "./InventoryAdmin/DispatchDetails";
// import ManagerDetails from "./SupperAdmin/ManagerDetails";
// import ChangePassword from "./DriverAdmin/ChangePass.jsx";
// import ChangePasswordLogs from "./InventoryAdmin/ChangePasswordLogs.jsx";
// import LogsSet from "./InventoryAdmin/LogisticsSet.jsx";
// import ResetPass from "./SupperAdmin/Reset-pass.jsx";
// import OverView from "./SupperAdmin/OverView.jsx";
// import ActiveDispatch from "./InventoryAdmin/ActiveDispatch.jsx";
// import AssignmentUtilityDispatch from "./InventoryAdmin/AssignmentUtilityDispatch.jsx";
// import AllFaqs from "./Pages/All-Faqs.jsx"


// function App() {
//   const location = useLocation();
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [location]);

//   const { isLoggedIn, user } = useContext(AuthContext);

//   if (!isLoggedIn) {
//     return (
//       <Fragment>
//         <Routes>
//           <Route path="/" element={<Navbar />}>
//             <Route index element={<Home />} />
//             <Route path="/AboutUs" element={<AboutUs />} />
//             <Route path="/OurOffices" element={<OurOffices />} />
//             <Route path="/GetQuotes" element={<GetQuotes />} />
//             <Route path="/ContactUs" element={<ContactUs />} />
//             <Route path="/Services" element={<Services />} />
//             <Route path="/Login" element={<Login />} />
//             <Route path="/ForgotPassword" element={<ForgotPassword />} />
//             <Route path="/AllFaqs" element={<AllFaqs/>} />
//             <Route path="*" element={<NotFound />} />
//           </Route>
//         </Routes>
//         <Footer />
//       </Fragment>
//     );
//   }

//   if (user?.user_role?.role_name_slug === "super-admin") {
//     return (
//       <Routes>
//         <Route path="/" element={<SupperAdmin />}>
//           <Route path="/Logistics" element={<Logistics />} />
//           <Route path="/manager-details/:id" element={<ManagerDetails />} />
//           <Route path="/Settings" element={<Settings />} />
//           <Route path="/Driver" element={<Driver />} />
//           <Route path="/Profile" element={<Profile />} />
//           <Route path="/AddOfficers" element={<AddOfficers />} />
//           <Route path="/AddDrivers" element={<AddDrivers />} />
//           <Route path="/drivers/:id" element={<FullInfoDriver />} />
//           <Route path="/AddAdmin" element={<AddAdmin />} />
//           <Route path="/ResetPass" element={<ResetPass />} />
//           <Route path="/OverView" element={<OverView />} />
//         </Route>
//       </Routes>
//     );
//   } else if (user?.user_role?.role_name_slug === "inventory-manager") {
//     return (
//       <Routes>
//         <Route path="/" element={<InventoryAdmin />}>
//           <Route path="/InventoryDriver" element={<InventoryDriver />} />
//           <Route path="/ShippingAddress" element={<ShippingAddress />} />
//           <Route path="/ItemsInfo" element={<ItemsInfo />} />
//           <Route path="/Processing" element={<Processing />} />
//           <Route path="/CreatePackages" element={<CreatePackages />} />
//           <Route path="/packages/:id" element={<PackageDetails />} />
//           <Route path="/CreateCategory" element={<CreateCategory />} />
//           <Route path="/ChangePasswordLogs" element={<ChangePasswordLogs />} />
//           <Route path="/ActiveDispatch" element={<ActiveDispatch />} />
//           <Route path="/LogsSet" element={<LogsSet />} />
//           <Route path="/AssignmentUtilityDispatch" element={<AssignmentUtilityDispatch />} />
//           <Route
//             path="/order-details/:dispatchIdentifier"
//             element={<DispatchDetails />}
//           />
//         </Route>
//       </Routes>
//     );
//   } else if (user?.user_role?.role_name_slug === "driver") {
//     return (
//       <Routes>
//         <Route path="/" element={<DriverAdmin />}>
//           <Route path="/DriverHome" element={<DriverHome />} />
//           <Route path="/ChangePassword" element={<ChangePassword />} />
//         </Route>
//       </Routes>
//     );
//   } else {
//     return <Navigate to="/NotFound" />;
//   }
// }

// export default App;




import React, { Fragment, useEffect, useContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Navbar from "./Navigation/Navbar";
import Home from "./Routes/Home";
import AboutUs from "./Routes/About-Us.jsx";
import OurOffices from "./Routes/Our-Offices";
import GetQuotes from "./Routes/Get-Qoutes";
import ContactUs from "./Routes/Contact-us.jsx";
import Footer from "./Pages/Footer";
import Services from "./Routes/Services";
import Login from "./Auth/Login";
import ForgotPassword from "./Auth/Forgotpassword";
import { AuthContext } from "./Context/context";
import ShippingAddress from "./Logistics/ShippingAddress";
import ItemsInfo from "./Logistics/ItemsInfo";
import Processing from "./Logistics/Processing";
import SupperAdmin from "./SupperAdmin/SupperAdmin";
import InventoryAdmin from "./InventoryAdmin/InventoryAdmin";
import DriverAdmin from "./DriverAdmin/DriverAdmin";
import NotFound from "./notFound";
import Settings from "./Pages/Settings";
import Driver from "./SupperAdmin/Driver";
import Profile from "./SupperAdmin/Profile.jsx";
import Logistics from "./SupperAdmin/Logistic";
import AddOfficers from "./SupperAdmin/Add-Officers";
import AddDrivers from "./SupperAdmin/Add-driver";
import FullInfoDriver from "./SupperAdmin/FullInfoDriver";
import DriverHome from "./DriverAdmin/Driverhome";
import AddAdmin from "./SupperAdmin/Add-addmin";
import InventoryDriver from "./InventoryAdmin/InventoryDriver";
import CreatePackages from "./InventoryAdmin/CreatePackage";
import PackageDetails from "./InventoryAdmin/ PackageDetails";
import CreateCategory from "./InventoryAdmin/CreateCategory";
import DispatchDetails from "./InventoryAdmin/DispatchDetails";
import ManagerDetails from "./SupperAdmin/ManagerDetails";
import ChangePassword from "./DriverAdmin/ChangePass.jsx";
import ChangePasswordLogs from "./InventoryAdmin/ChangePasswordLogs.jsx";
import LogsSet from "./InventoryAdmin/LogisticsSet.jsx";
import ResetPass from "./SupperAdmin/Reset-pass.jsx";
import OverView from "./SupperAdmin/OverView.jsx";
import ActiveDispatch from "./InventoryAdmin/ActiveDispatch.jsx";
import AssignmentUtilityDispatch from "./InventoryAdmin/AssignmentUtilityDispatch.jsx";
import AllFaqs from "./Pages/All-Faqs.jsx";


function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const { isLoggedIn, user } = useContext(AuthContext);

  const renderHelmet = () => (
    <Helmet>
      <title>Doorstep - Logistics Company</title>
      <meta name="description" content="Doorstep is a leading logistics company offering top-notch delivery services." />
      <link rel="canonical" href="https://www.doorstepng.com/" />
    </Helmet>
  );

  if (!isLoggedIn) {
    return (
      <Fragment>
        {renderHelmet()}
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/OurOffices" element={<OurOffices />} />
            <Route path="/GetQuotes" element={<GetQuotes />} />
            <Route path="/ContactUs" element={<ContactUs />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/ForgotPassword" element={<ForgotPassword />} />
            <Route path="/AllFaqs" element={<AllFaqs/>} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        <Footer />
      </Fragment>
    );
  }

  if (user?.user_role?.role_name_slug === "super-admin") {
    return (
      <Fragment>
        {renderHelmet()}
        <Routes>
          <Route path="/" element={<SupperAdmin />}>
            <Route path="/Logistics" element={<Logistics />} />
            <Route path="/manager-details/:id" element={<ManagerDetails />} />
            <Route path="/Settings" element={<Settings />} />
            <Route path="/Driver" element={<Driver />} />
            <Route path="/Profile" element={<Profile />} />
            <Route path="/AddOfficers" element={<AddOfficers />} />
            <Route path="/AddDrivers" element={<AddDrivers />} />
            <Route path="/drivers/:id" element={<FullInfoDriver />} />
            <Route path="/AddAdmin" element={<AddAdmin />} />
            <Route path="/ResetPass" element={<ResetPass />} />
            <Route path="/OverView" element={<OverView />} />
          </Route>
        </Routes>
      </Fragment>
    );
  } else if (user?.user_role?.role_name_slug === "inventory-manager") {
    return (
      <Fragment>
        {renderHelmet()}
        <Routes>
          <Route path="/" element={<InventoryAdmin />}>
            <Route path="/InventoryDriver" element={<InventoryDriver />} />
            <Route path="/ShippingAddress" element={<ShippingAddress />} />
            <Route path="/ItemsInfo" element={<ItemsInfo />} />
            <Route path="/Processing" element={<Processing />} />
            <Route path="/CreatePackages" element={<CreatePackages />} />
            <Route path="/packages/:id" element={<PackageDetails />} />
            <Route path="/CreateCategory" element={<CreateCategory />} />
            <Route path="/ChangePasswordLogs" element={<ChangePasswordLogs />} />
            <Route path="/ActiveDispatch" element={<ActiveDispatch />} />
            <Route path="/LogsSet" element={<LogsSet />} />
            <Route path="/AssignmentUtilityDispatch" element={<AssignmentUtilityDispatch />} />
            <Route
              path="/order-details/:dispatchIdentifier"
              element={<DispatchDetails />}
            />
          </Route>
        </Routes>
      </Fragment>
    );
  } else if (user?.user_role?.role_name_slug === "driver") {
    return (
      <Fragment>
        {renderHelmet()}
        <Routes>
          <Route path="/" element={<DriverAdmin />}>
            <Route path="/DriverHome" element={<DriverHome />} />
            <Route path="/ChangePassword" element={<ChangePassword />} />
          </Route>
        </Routes>
      </Fragment>
    );
  } else {
    return <Navigate to="/NotFound" />;
  }
}

export default App;

