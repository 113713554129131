import React, { useState, useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../Context/context";
import { useNavigate } from "react-router-dom";
import "../Css/Dispatch.css";
import { Link } from "react-router-dom";

const CreatePackages = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const formatCurrency = (amountInKobo) => {
    const amountInNaira = amountInKobo / 100; 
    return amountInNaira.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    });
  };

  const { data: packages, error } = useQuery(
    "packages",
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Packages`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message);
      }

      const data = await response.json();
      console.log(data.results);
      return data.results;
    },
    {
      enabled: !!token,
    }
  );

  const filteredPackages = packages?.filter(
    (pkg) =>
      pkg.identifier.toLowerCase().includes(searchValue.toLowerCase()) &&
      pkg.dispatch.status.toLowerCase() === "in-warehouse"
  );

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className="create-packages">
      <div className="btns-packs">
        <span>
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="search"
            placeholder="Search packages by ID"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </span>
        <button onClick={() => navigate("/ShippingAddress")}>
          Create Package
        </button>
      </div>
      <div className="package-table">
        {error && <p>Error: {error}</p>}
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Quantity</th>
              <th>Dispatch Status</th>
              <th>Shipping</th>
              <th>Sender Name</th>
              <th>Receiver Name</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {filteredPackages?.map((pkg) => (
              <tr key={pkg.identifier}>
                <td>{pkg.identifier.slice(0, 12).toUpperCase()}</td>
                <td>{pkg.name}</td>
                <td>{pkg.quantity}</td>
                <td>{pkg.dispatch.status}</td>
                <td>{formatCurrency(pkg.shipping_cost)}</td>
                <td>{pkg.shipping_information.sender.name}</td>
                <td>{pkg.shipping_information.receiver.name}</td>
                <td>
                  <Link to={`/packages/${pkg.identifier}`}>Details</Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CreatePackages;


