import { ReactComponent as ArrowIcon } from "../assets/ri_arrow-drop-down-line.svg";
import { useContext } from "react";
import { StateContext } from "../Context/states-context";

const Chatus = () => {
  const { chatBoat, hide, setHide } = useContext(StateContext);
  return (
    <div className={`chat-us-container ${chatBoat ? "active" : ""}`}>
      <div className="chat-header">
        <span>
          <p>Chat with us</p>
          <ArrowIcon onClick={() => setHide(!hide)} />
        </span>
        {hide && (
          <p>
            Our staff are off for the weekend, We will be back in the next 2
            days, after the weekends.
          </p>
        )}
      </div>
    </div>
  );
};

export default Chatus;
