import React, { useEffect, useState, useContext, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/context";
import Spinner from "../Spinner/Spinner";
import UpdateShippingInfoForm from "./UpdateShippingInfoForm";

const PackageDetails = () => {
  const { token } = useContext(AuthContext);
  const [confirm, setConfirm] = useState(false);
  const [packageDetails, setPackageDetails] = useState({});
  const [isShipping, setIssphinnp] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [successfully, setSuccefully] = useState(false);
  const [editedPackage, setEditedPackage] = useState({
    name: "",
    weight: "",
    quantity: "",
    insuranceType: "",
  });

  const formatCurrency = (amountInKobo) => {
    const amountInNaira = amountInKobo / 100; // Convert from kobo to naira
    return amountInNaira.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    });
  };
  

  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPackageDetails = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Package/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        // console.log("userdata",data);

        if (!response.ok) {
          throw new Error(data.message);
        }

        setPackageDetails(data.results);
        setEditedPackage({
          name: data.results.name,
          weight: data.results.weight,
          quantity: data.results.quantity,
          insuranceType: data.results.insurance_type || "",
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackageDetails();
  }, [id, token]);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Delete/Packages/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }
      setIsLoading(false);
      navigate("/CreatePackages");
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      console.log("Update Payload:", editedPackage);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Update/Packages/${id}/PackageInformation`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: editedPackage.name,
            weight: editedPackage.weight,
            quantity: editedPackage.quantity,
            insuranceType: editedPackage.insuranceType,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }
      setIsLoading(false);
      setSuccefully("Package Information updated successfully");
      setTimeout(() => {
        setSuccefully(null);
      }, 3000);
      console.log(":", data.results);
      setIsEdit(false);
    } catch (error) {
      setError(error.message);
    }
  };

 

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const parsedValue = value !== "" ? parseFloat(value) : "";
    setEditedPackage({ ...editedPackage, [name]: parsedValue });
  };
  

  const handleUpdateShipping = () => {
    setIssphinnp(true);
  };

 
  const handleCancelShipping = () => {
    setIssphinnp(false);
  };

  return (
    <Fragment>
      {confirm && (
        <div className="delete-con">
          <div className="box">
          <p>Are you sure you want to delete this package?</p>
          <span>
            <button onClick={() => setConfirm(false)}>Cancel</button>
            <button onClick={handleDelete}>
                {isLoading ? <Spinner /> : "Delete Package"}
              </button>
          </span>
          </div>
        </div>
      )}

      {isShipping && (
        <UpdateShippingInfoForm handleCancelShipping={handleCancelShipping} />
      )}
      {isEdit && (
        <div className="edit-details">
          <form>
            <span>
              <label>
                Name
                <input
                  type="text"
                  name="name"
                  value={editedPackage.name}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Weight
                <input
                  type="number"
                  name="weight"
                  value={editedPackage.weight}
                  onChange={handleInputChange}
                />
              </label>
            </span>

            <span>
              <label>
                Quantity
                <input
                  type="number"
                  name="quantity"
                  value={editedPackage.quantity}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Insurance Type
                <input
                  type="text"
                  name="insuranceType"
                  value={editedPackage.insuranceType}
                  onChange={handleInputChange}
                />
              </label>
            </span>

            <span>
              <div className="pkg-update">
                <button type="button" onClick={() => setIsEdit(false)}>
                  Cancel
                </button>
                <button type="button" onClick={handleUpdate}>
                  {isLoading ? <Spinner /> : "Update"}
                </button>
              </div>
            </span>
          </form>
        </div>
      )}
      <div className="package-details">
        <div className="btns-packs">
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => navigate("/CreatePackages")}
          ></i>
          <h3 style={{fontFamily:"Akira", color:"#294697"}}>Package Details</h3>
        </div>
        {!isLoading && (
          <div className="package-card">
            {error && <p>Error: {error}</p>}
            <span>
              <h3>Package Details</h3>
              <p>Status: {packageDetails.dispatch.status}</p>
              <p>Name: {packageDetails.name}</p>
              <p>Weight: {packageDetails.weight}</p>
              <p>Quantity: {packageDetails.quantity}</p>
              {packageDetails.category && (
                <p>Category: {packageDetails.category.name}</p>
              )}
              {packageDetails.dispatch && (
                <p>Dispatch Status: {packageDetails.dispatch.status}</p>
              )}
              <p>Insurance Type: {packageDetails.insurance_type}</p>
              <p>
                Shipping Cost: {formatCurrency(packageDetails.shipping_cost)}
              </p>
            </span>

            <span>
              <h3>Sender Information</h3>
              {packageDetails.shipping_information?.sender && (
                <>
                  <p>
                    Sender Name:{" "}
                    {packageDetails.shipping_information.sender.name}
                  </p>
                  <p>
                    Sender Phone Number:{" "}
                    {packageDetails.shipping_information.sender.phone_number}
                  </p>
                  <p>
                    Sender Email:{" "}
                    {packageDetails.shipping_information.sender.email}
                  </p>

                  <p>
                    Sender State:{" "}
                    {packageDetails.shipping_information.sender.state.state_label}
                  </p>

                  <p>
                    Sender Adress:{" "}
                    {packageDetails.shipping_information.sender.address_1}
                  </p>
                </>
              )}
            </span>

            <span>
              <h3>Receiver Information</h3>
              {packageDetails.shipping_information?.receiver && (
                <>
                  <p>
                    Receiver Name:{" "}
                    {packageDetails.shipping_information.receiver.name}
                  </p>
                  <p>
                    Receiver Phone Number:{" "}
                    {packageDetails.shipping_information.receiver.phone_number}
                  </p>
                  <p>
                    Receiver Email:{" "}
                    {packageDetails.shipping_information.receiver.email}
                  </p>

                  <p>
                    Receiver State:{" "}
                    {packageDetails.shipping_information.receiver.state.state_label}
                  </p>

                  <p>
                    Receiver Delivery Address:{" "}
                    {packageDetails.shipping_information.receiver.address_1}
                  </p>
                </>
              )}
            </span>
            <div className="span">
              <button type="button" onClick={() => setIsEdit(true)}>
                Update Package
              </button>
              <button onClick={handleUpdateShipping}>
                Update Shipping Info
              </button>
              <button onClick={() => setConfirm(true)}>Delete</button>
              {successfully && <p style={{ color: "green" }}>{successfully}</p>}
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default PackageDetails;