import React, { useState, useContext } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { AuthContext } from "../Context/context";
import Spinner from "../Spinner/Spinner";
import "../Css/Inventory.css";

const defaultForm = {
  name: "",
  description: "",
};

const CreateCategory = () => {
  const [formField, setFormField] = useState(defaultForm);
  const [updateForm, setUpdateForm] = useState(defaultForm);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [succeesMsg, setSucceesMsg] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryIdentifier, setSelectedCategoryIdentifier] = useState(null);
  const { name, description } = formField;
  const { token } = useContext(AuthContext);

  const queryClient = useQueryClient();

  // Fetch categories query
  const { data: categories = [] } = useQuery(
    "categories",
    async () => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/PackageCategories`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      return data.results.reverse();
    },
    {
      onError: (error) => {
        console.error("Error fetching categories:", error);
      },
    }
  );

  // Create category mutation
  const createCategoryMutation = useMutation(
    async (newCategory) => {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Create/PackageCategory/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newCategory),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      return data;
    },
    {
      onSuccess: () => {
        setIsLoading(false);
        setSucceesMsg("Category Created Successfully");
        setTimeout(() => {
          setSucceesMsg("");
        }, 3000);
        queryClient.invalidateQueries("categories"); // Refetch categories after create
      },
      onError: (error) => {
        setError(error.message);
        setIsLoading(false);
      },
    }
  );

  // Update category mutation
  const updateCategoryMutation = useMutation(
    async ({ identifier, updatedCategory }) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Update/PackageCategory/${identifier}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedCategory),
        }
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      return data;
    },
    {
      onSuccess: () => {
        setIsLoading(false);
        setSucceesMsg("Category Updated Successfully");
        setTimeout(() => {
          setSucceesMsg("");
        }, 3000);
        queryClient.invalidateQueries("categories"); // Refetch categories after update
      },
      onError: (error) => {
        setError(error.message);
        setIsLoading(false);
      },
    }
  );

  // Delete category mutation
  const deleteCategoryMutation = useMutation(
    async (categoryIdentifier) => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Delete/PackageCategory/${categoryIdentifier}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
      return data;
    },
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries("categories"); // Refetch categories after delete
      },
      onError: (error) => {
        setError(error.message);
      },
    }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField({ ...formField, [name]: value });
    setUpdateForm({ ...updateForm, [name]: value });
    setError(false);
  };

  const handleSubmitCreate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    createCategoryMutation.mutate(formField);
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    setIsLoading(true);
    updateCategoryMutation.mutate({
      identifier: selectedCategoryIdentifier,
      updatedCategory: updateForm,
    });
  };

  const deleteCategory = (categoryIdentifier) => {
    deleteCategoryMutation.mutate(categoryIdentifier);
  };

  const updateCategory = (category) => {
    setUpdateForm({
      name: category.name,
      description: category.description,
    });
    setSelectedCategory(category);
    setSelectedCategoryIdentifier(category.identifier);
  };

  return (
    <div className="category-container">
      <span>
        <h1>Category</h1>
      </span>
      <div className="wrapper">
        <form onSubmit={selectedCategory ? handleSubmitUpdate : handleSubmitCreate}>
          <h2>{selectedCategory ? "Update Category" : "Create Category"}</h2>
          <div className="input">
            <label>
              Category Name
              <input
                type="text"
                name="name"
                value={selectedCategory ? updateForm.name : name}
                onChange={handleChange}
                required
              />
            </label>
            <label>
              Description
              <input
                name="description"
                value={selectedCategory ? updateForm.description : description}
                onChange={handleChange}
                required
              />
            </label>
          </div>
          <button type="submit">
            {isLoading ? <Spinner /> : selectedCategory ? "Update Category" : "Create Category"}
          </button>

          {error && (
            <span style={{ margin: "auto", color: "red" ,fontFamily:"Montserrat"}}>
              Error {selectedCategory ? "updating" : "creating"} category.
            </span>
          )}
          {succeesMsg && (
            <p style={{ margin: "auto", color: "Green", fontFamily:"Montserrat" }}>{succeesMsg}</p>
          )}
        </form>
        <div className="category-list">
          <h2>Categories</h2>
          <ul>
            {categories.map((category) => (
              <div className="card" key={category.identifier}>
                <li> Name: {category.name}</li>
                <li>Description: {category.description}</li>
                <span>
                  <button onClick={() => updateCategory(category)}>Update Category</button>
                  <button onClick={() => deleteCategory(category.identifier)}>Delete</button>
                </span>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CreateCategory;



