import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Helmet } from "react-helmet";
import "../Css/Ouroffice.css";

const OurOffices = () => {
  const navigate = useNavigate()
  return (
    <Fragment>
       <Helmet>
        <title>Our offices| Doorstep</title>
        <meta name="description" content="Our offices page of Doorstep, your trusted logistics partner." />
        <link rel="icon" href="/public/favicon.ico" />
        <meta property="og:type" content="website" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:url" content="https://www.doorstepng.com/" />

  
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta name="twitter:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta name="twitter:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="instapp:site_name" content="Doorstep" />
  <meta property="instapp:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="instapp:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="instapp:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />

  
  <meta property="tiktok:card" content="summary_large_image" />
  <meta property="tiktok:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="tiktok:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="tiktok:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:url" content="https://www.doorstepng.com/" />
      </Helmet>
   
    <div className="ouroffices">
      <div className="wrappings">
        <div className="box">
          <h1>Our Offices</h1>
          <p>We are Located in Various Locations around the nation</p>
          <div className="address">
            <span>
              <h2>Lekki</h2>
              <p>
                17A, John-Vic Igboanugo Street, Off Chris Madueke Drive, Lekki
                Phase One, Lagos, Lagos, Nigeria 106104, NG
              </p>
            </span>
            <span>
              <h2>Jibowu</h2>
              <p>22, Jibowu Street, Jibowu Bus stop, along Ikorodu Road</p>
            </span>
            <span>
              <h2>Ikeja</h2>
              <p>
                BLOCK 51E, Obi'S Village , Cargo Village, Local Airport Ikeja
                Lagos
              </p>
            </span>

            <span>
              <h2>Port Harcourt:</h2>
              <p>
                4,0wu street Rumuklagbor beside waterline junction (Okey Elikwu
                family compound)
              </p>
            </span>
          </div>
        </div>
        <div className="image">
          <h3>Get a Quick Quote</h3>
          <p>
            Calculate the cost estimate for local and international shipments
          </p>
          <button onClick={() => navigate('/GetQuotes')}>GET QOUTES</button>
        </div>
      </div>
      <div className="contact">
        <h1>Contact Us</h1>
        <div className="wrapper">
          <div className="inner">
            <span>
              <i className="fa-solid fa-envelope"></i>
              <h2>Email Us</h2>
            </span>
            <p>Complaint?</p>
            <h5>Send us an Email and we would respond to you shortly</h5>
            <h4><a href="mailto:hello@doorstepng.com">hello@doorstepng.com</a></h4>
          </div>
          <div className="inner">
            <span>
              <i className="fa-solid fa-phone"></i>
              <h2>Call Us</h2>
            </span>
            <p>Customer Service</p>
            <h5>
              Questions and support for shipment status, delivery and courier
              pickups.
            </h5>
            <h4><a href="tel:013300242">013300242</a></h4>
          </div>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default OurOffices;
