import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/context";

const defaultForm = {
  email: "",
  token: "",
  password: "",
};

export function useForgotPassword() {
  const [formField, setFormField] = useState(defaultForm);
  const [isEmail, setIsEmail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const { token} = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField({ ...formField, [name]: value });
  };

  const handleRequestPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Request/PasswordResetToken`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ email: formField.email }),
        }
      );

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message);
      }

      setIsLoading(false);

      if (
        responseData.status_code === 200 &&
        responseData.status === "success"
      ) {
        setIsEmail(false);
      } else {
        setError("Password Reset Otp not sent successfully.");
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      // Validate the password reset token before proceeding
      const validateResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Validate/PasswordResetToken`,
        {
          method: "POST",
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            email: formField.email,
            token: formField.token,
          }),
        }
      );
  
      const validateData = await validateResponse.json();
  
      if (!validateResponse.ok) {
        throw new Error(validateData.message);
      }
  
      if (validateData.status_code === 200 && validateData.status === "success") {

  
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Process/ResetPassword`,
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              password: formField.password,
            }),
          }
        );
  
        const responseData = await response.json();
  
        if (!response.ok) {
          throw new Error(responseData.message);
        }
  
        if (responseData.status_code === 200 && responseData.status === "success") {
          setSuccessMsg("Your password is updated successfully");
          

          setTimeout(() => {
            setSuccessMsg(null);
            navigate("/Login");
            setIsLoading(false);
          }, 3000);
        } else {
          setError("Reset Password Failed: " + responseData.message);
          setIsLoading(false);
        }
      } else {
        setError("Invalid or expired password reset token.");
        setIsLoading(false);
        console.log("Token validation failed");
      }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      console.error("Password reset error:", error);
    }
  };
  

  return {
    formField,
    isEmail,
    isLoading,
    error,
    successMsg,
    handleChange,
    handleRequestPassword,
    handleResetPassword,
  };
}
