import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../Context/context";
import { useQuery } from "react-query";
import "../Css/super.css";

const Driver = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchDrivers = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Drivers`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();
    console.log("drivers", data);

    if (!response.ok) {
      throw new Error(data.message);
    }

    return data.results;
  };

  const fetchSuspensions = async (startDateRange, endDateRange) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Suspensions/Staff`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
            start_date_range: startDateRange,
            end_date_range: endDateRange,
            is_active: false,
            staff_type: "drivers",
          },
        }
      );

      const data = await response.json();

      console.log("suspended drivers", data);

      if (!response.ok) {
        throw new Error(data.message);
      }

      return data.results.suspensions;
    } catch (error) {
      console.error("Error fetching suspensions:", error);
      throw error;
    }
  };

  const {
    data: drivers,
    isLoading: isDriversLoading,
    isError: isDriversError,
  } = useQuery("drivers", fetchDrivers, {
    enabled: !!token,
  });

  const {
    data: suspensions,
    isLoading: isSuspensionsLoading,
    isError: isSuspensionsError,
    isRefetching,
  } = useQuery(
    [
      "suspensions",
      {
        page: currentPage,
        per_page: itemsPerPage,
        isActive: false,
        staffType: "driver",
      },
    ],
    fetchSuspensions,
    {
      enabled: !!token,
    }
  );

  const isDriverSuspended = (driverId) => {
    return (
      suspensions &&
      suspensions.length > 0 &&
      suspensions.some(
        (suspension) =>
          suspension.driver &&
          suspension.driver.identifier === driverId &&
          suspension.is_active
      )
    );
  };

  const filteredDrivers = drivers
    ? drivers.filter((driver) =>
        driver.full_name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  if (isDriversLoading || isSuspensionsLoading) {
    return <p>Loading...</p>;
  }

  if (isDriversError || isSuspensionsError) {
    return <p>Error fetching data</p>;
  }

  return (
    <div className="driver-container">
      <div className="add-driver">
        <span>
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="search"
            placeholder="Search driver by name...."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
        <button onClick={() => navigate("/AddDrivers")}>Add Driver</button>
      </div>
      <div className="package-table">
        <table>
          <thead>
            <tr>
              <th>Driver ID</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Vehicle Type</th>
              <th>Plate Number</th>
              <th>Register Vehicle</th>
              <th>View Full Info</th>
              <th>Status</th>
            </tr>
          </thead>
          {drivers.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan="8">No Drivers found.</td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {filteredDrivers.map((driver) => (
                <tr key={driver.identifier}>
                  <td>ID{driver.drivers_license_number}</td>
                  <td>{driver.full_name}</td>
                  <td>{driver.email}</td>
                  <td>{driver.vehicle_type}</td>
                  <td>{driver.vehicle_plate_number}</td>
                  <td>{driver.vehicle_registration_number}</td>
                  <td>
                    <Link to={`/drivers/${driver.identifier}`}>
                      View Full Info
                    </Link>
                  </td>
                  <td>
                    {isDriverSuspended(driver.identifier) ? (
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: "red" }}
                      ></i>
                    ) : (
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: "green" }}
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div className="pagination-controls">
        <div className="shows">
          Showing {currentPage * itemsPerPage - itemsPerPage + 1} -{" "}
          {Math.min(currentPage * itemsPerPage, drivers.length)} of{" "}
          {drivers.length} Drivers
        </div>
        <div className="pagination-buttons">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1 || isRefetching}
          >
            {"<"}
          </button>
          {Array.from(
            { length: Math.ceil(drivers.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                disabled={currentPage === index + 1 || isRefetching}
              >
                {index + 1}
              </button>
            )
          )}
          <button
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(prev + 1, Math.ceil(drivers.length / itemsPerPage))
              )
            }
            disabled={
              currentPage === Math.ceil(drivers.length / itemsPerPage) ||
              isRefetching
            }
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Driver;

