import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../Context/context";
import "../Css/Dispatch.css";

const ActiveDispatch = () => {
  const { token } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");

  const fetchDispatchRequests = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Manager/Fetch/DispatchRequests`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error(`Error fetching dispatch requests: ${error.message}`);
    }
  };

  const { data, isLoading, error } = useQuery(
    "dispatchRequests",
    fetchDispatchRequests
  );

  const filteredDispatchRequests = data?.results?.filter((dispatchRequest) =>
  dispatchRequest.identifier.toLowerCase().includes(searchTerm.toLowerCase())
);


  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="dedicated-dispatch">
      <div className="dispatch-header">
        <h1>Active Dispatches</h1>
        <span>
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="search"
            placeholder="Search packages by ID"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </span>
      </div>
      <div className="package-table">
        <table>
          <thead>
            <tr>
              <th>Package ID</th>
              <th>Estimated Delivery date</th>
              <th>Status</th>
              <th>Request Status</th>
              <th>Email</th>
              <th>Driver</th>
            </tr>
          </thead>
          <tbody>
            {filteredDispatchRequests?.map((dispatchRequest) => (
              <tr key={dispatchRequest.identifier}>
                <td>{dispatchRequest.identifier.slice(0, 12).toUpperCase()}</td>
                <td>
                  {dispatchRequest.dispatch?.package?.shipping_information?.estimated_delivery_date}
                </td>
                <td>{dispatchRequest.dispatch?.status}</td>
                <td>{dispatchRequest.status}</td>
                <td>{dispatchRequest.driver?.email}</td>
                <td>
                  {dispatchRequest.driver ? (
                    <div>{dispatchRequest.driver.full_name}</div>
                  ) : (
                    <div>Not accepted by driver yet</div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActiveDispatch;

