import { createContext, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext({
  isMobile: false,
  setIsmobile: () => {},
  isDropDown: false,
  setIsDropDown: () => {},
  isPassWord: false,
  setIsPassword: () => {},
  isLoggedIn: false,
  user: null,
  token: null,
  setUserType: () => {},
  Login: () => {},
  LogOut: () => {},
  isLoggedInDrop: false,
  setisLoggedInDrop: () => {},
  editEmail: false,
  setEditEmail: () => {},
  editPhone: false,
  setEditPhone: () => {},
  isEdit: false,
  setIsEdit: () => {},
});

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isMobile, setIsmobile] = useState(false);
  const [isDropDown, setIsDropDown] = useState(false);
  const [isPassWord, setIsPassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [isLoggedInDrop, setisLoggedInDrop] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [pass, setPass] = useState(false);

  const handlePass = () => {
    setPass(true);
  };

  const Login = useCallback((user) => {
    setIsLoggedIn(true);
    setUser(user);
    setToken(user.token);
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("user", JSON.stringify(user));
  }, []);

  const LogOut = useCallback(() => {
    setIsLoggedIn(false);
    setUser(null);
    setToken(null);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("user");
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    const storedUser = JSON.parse(localStorage.getItem("user"));

    if (storedIsLoggedIn === "true" && storedUser) {
      setIsLoggedIn(true);
      setUser(storedUser);
      setToken(storedUser.token);
    }
  }, []);

  const value = {
    isMobile,
    setIsmobile,
    isDropDown,
    setIsDropDown,
    isPassWord,
    setIsPassword,
    isLoggedIn,
    user,
    token,
    Login,
    LogOut,
    isLoggedInDrop,
    setisLoggedInDrop,
    editEmail,
    setEditEmail,
    editPhone,
    setEditPhone,
    isEdit,
    setIsEdit,
    handlePass,
    pass,
    setPass,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
