import "../Css/About.css";
import { Fragment } from "react";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <Fragment>
       <Helmet>
        <title>About us | Doorstep</title>
        <meta name="description" content="About us page of Doorstep, your trusted logistics partner." />
        <link rel="icon" href="/public/favicon.ico" />
        <meta property="og:type" content="website" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:url" content="https://www.doorstepng.com/" />

  
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta name="twitter:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta name="twitter:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="instapp:site_name" content="Doorstep" />
  <meta property="instapp:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="instapp:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="instapp:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />

  
  <meta property="tiktok:card" content="summary_large_image" />
  <meta property="tiktok:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="tiktok:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="tiktok:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:url" content="https://www.doorstepng.com/" />
      </Helmet>
   
    <div className="about-container">
      <div className="about-banner">
        <div className="overley">
          <h1>Every delivery is</h1>
          <h1>a promise kept.</h1>
          <p>A reputation for speed and reliability</p>
        </div>
      </div>
      <div className="door-step">
        <div className="wrapper">
          <h1>DOORSTEP LOGISTICS</h1>
          <span>
            <h1>DOORSTEP</h1>
            <h1>LOGISTICS</h1>
          </span>
          {/* <p>
            Welcome to Doorstep Logistics, a distinguished name in the world of
            logistics. Established with a vision to redefine the logistics
            industry, we have grown into a reliable and innovative partner for
            businesses worldwide. With years of experience and a passion for
            excellence, we have consistently delivered tailored logistics
            solutions that drive growth and efficiency for our clients. Our
            journey began 10 years ago with a handful of dedicated professionals
            who shared a common goal: to revolutionize logistics. Starting from
            modest beginnings, we quickly gained recognition for our commitment
            to quality service and a forward-thinking approach. Today, we stand
            as a testament to the power of perseverance and the impact of
            innovation.
          </p> */}
          <p>
            Doorstep Logistics and Transportation Services, established in 2018,
            is a leading logistics company headquartered in Lagos, with a robust
            network of branches nationwide. As an air express company
            specializing in express delivery services and logistics support, we
            have earned a stellar reputation for our commitment to quality
            services and a dedicated team of over 37 employees.
          </p>
        </div>
      </div>
      <div className="our-vision">
        <div className="wrapper">
          <div className="box">
            <span>
              <h1>OUR VISION</h1>
              {/* <p>
                Our vision is to empower businesses by providing seamless
                logistics solutions that bridge gaps and connect markets.
              </p> */}
              <p className="long">
                At Doorstep Logistics, we go beyond being a logistics provider;
                we are a family dedicated to providing customer-driven services.
                Our mission is to seamlessly connect points of origin with
                destinations, ensuring every product, package, and parcel feels
                like it's merely being passed from one room to another,
                regardless of geographical distances. Our Vision is to redefine
                the world of logistics, making every delivery endpoint a
                personalized experience, turning the essence of logistics into
                an extension of our customers' home or business.
              </p>
            </span>
            <div className="value">
              <h1>CORE VALUES</h1>
              <p>
                Our vision is to empower businesses by providing seamless
                logistics solutions that bridge gaps and connect markets.
              </p>
              <p>
                Innovation: Embracing new technologies and strategies, we
                consistently seek better ways to optimize logistics processes
                and enhance client experiences.
              </p>
              <p>
                Integrity: Honesty and integrity are at the core of our
                interactions. We believe in transparent business practices that
                build trust with our clients, partners, and employees.
              </p>
            </div>
          </div>
          <div className="image">
            <img src="https://i.ibb.co/ygmxmFL/Vector.png" alt="" />
          </div>
        </div>
      </div>
      <div className="our-team">
        <div className="box">
          <h1>Meet Our Founder and Key Management Staff</h1>
          <p>
            Founded by Justin Chukwuemeka Enuonye, who brings extensive
            experience from the oil and gas industry, Doorstep Logistics is led
            by a dynamic team of professionals. From the Chief Operation Officer
            to the Fleet Manager and Commercial Manager, our key management
            staff collectively ensures Doorstep Logistics continues to grow as
            one of the fastest-growing logistics companies in Nigeria. We are
            more than a logistics provider. We are your trusted logistics
            family, committed to delivering excellence in every shipment.
          </p>
        </div>
        <div className="img">
          <img
            src="https://i.ibb.co/cTWRn1W/Layer-1-2x-1-removebg-preview.png"
            alt=""
          />
        </div>
      </div>
      <div className="choose-us">
        <h1>WHY CHOOSE US</h1>
        <div className="wrapper">
          <div className="box">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M25 12.5C25 19.4036 19.4036 25 12.5 25C5.59644 25 0 19.4036 0 12.5C0 5.59644 5.59644 0 12.5 0C19.4036 0 25 5.59644 25 12.5Z"
                  fill="#D0310E"
                />
                <path
                  d="M6 8C6 6.89543 6.89543 6 8 6H17C18.1046 6 19 6.89543 19 8V17C19 18.1046 18.1046 19 17 19H8C6.89543 19 6 18.1046 6 17V8Z"
                  fill="#D0310E"
                />
              </svg>
              <h4>Latest Technology</h4>
            </span>
            <p>
              We understand the role of technology in providing an exceptional
              customer experience. For this reason, our processes, products and
              services are built around modern technology to help us deliver
              your shipments cheaper, safely and at the speed of light.
            </p>
          </div>
          <div className="box">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M4.15593 16.731L3.08343 17.3589C2.93674 17.4449 2.81509 17.5678 2.73056 17.7154C2.64603 17.8629 2.60156 18.03 2.60156 18.2C2.60156 18.3701 2.64603 18.5372 2.73056 18.6847C2.81509 18.8323 2.93674 18.9551 3.08343 19.0411L12.5084 24.5661C12.6579 24.6537 12.8279 24.6998 13.0011 24.6998C13.1743 24.6998 13.3444 24.6537 13.4938 24.5661L22.9188 19.0411C23.0655 18.9551 23.1872 18.8323 23.2717 18.6847C23.3562 18.5372 23.4007 18.3701 23.4007 18.2C23.4007 18.03 23.3562 17.8629 23.2717 17.7154C23.1872 17.5678 23.0655 17.4449 22.9188 17.3589L21.8463 16.7297L14.4805 21.0483C14.0319 21.3114 13.5212 21.45 13.0011 21.45C12.4811 21.45 11.9704 21.3114 11.5217 21.0483L4.15593 16.731Z"
                  fill="#202020"
                />
                <path
                  d="M4.15593 11.5311L3.08343 12.159C2.93674 12.245 2.81509 12.3679 2.73056 12.5154C2.64603 12.663 2.60156 12.83 2.60156 13.0001C2.60156 13.1701 2.64603 13.3372 2.73056 13.4848C2.81509 13.6323 2.93674 13.7552 3.08343 13.8412L12.5084 19.3662C12.6579 19.4537 12.8279 19.4998 13.0011 19.4998C13.1743 19.4998 13.3444 19.4537 13.4938 19.3662L22.9188 13.8412C23.0655 13.7552 23.1872 13.6323 23.2717 13.4848C23.3562 13.3372 23.4007 13.1701 23.4007 13.0001C23.4007 12.83 23.3562 12.663 23.2717 12.5154C23.1872 12.3679 23.0655 12.245 22.9188 12.159L21.8463 11.5298L14.4805 15.8484C14.0319 16.1114 13.5212 16.2501 13.0011 16.2501C12.4811 16.2501 11.9704 16.1114 11.5217 15.8484L4.15593 11.5311Z"
                  fill="#202020"
                />
                <path
                  d="M13.4945 1.43397C13.3447 1.34596 13.1742 1.29956 13.0005 1.29956C12.8268 1.29956 12.6562 1.34596 12.5065 1.43397L3.08148 6.95897C2.93479 7.04498 2.81314 7.16785 2.72861 7.31539C2.64408 7.46294 2.59961 7.63003 2.59961 7.80007C2.59961 7.97012 2.64408 8.1372 2.72861 8.28475C2.81314 8.43229 2.93479 8.55517 3.08148 8.64117L12.5065 14.1662C12.6562 14.2542 12.8268 14.3006 13.0005 14.3006C13.1742 14.3006 13.3447 14.2542 13.4945 14.1662L22.9195 8.64117C23.0662 8.55517 23.1878 8.43229 23.2724 8.28475C23.3569 8.1372 23.4014 7.97012 23.4014 7.80007C23.4014 7.63003 23.3569 7.46294 23.2724 7.31539C23.1878 7.16785 23.0662 7.04498 22.9195 6.95897L13.4945 1.43397Z"
                  fill="#D0310E"
                />
              </svg>
              <h4>Social Commerce</h4>
            </span>
            <p>
              Pivotal to successful social commerce transactions is the ability
              for the end-user to receive items where and when needed. With an
              unmatched route network spanning urban and rural communalities,
              [company] is the solution to efficient last-mile delivery. As a
              merchant, you only have to think about selling your products while
              [company] delivers to your customers worldwide.
            </p>
          </div>
          <div className="box">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
              >
                <path
                  d="M12.9993 2.16675C11.5767 2.16675 10.168 2.44696 8.85361 2.99139C7.53925 3.53581 6.34499 4.33379 5.33903 5.33976C3.30738 7.3714 2.16602 10.1269 2.16602 13.0001C2.16602 15.8733 3.30738 18.6288 5.33903 20.6604C6.34499 21.6664 7.53925 22.4644 8.85361 23.0088C10.168 23.5532 11.5767 23.8334 12.9993 23.8334C15.8725 23.8334 18.628 22.692 20.6597 20.6604C22.6913 18.6288 23.8327 15.8733 23.8327 13.0001C23.8327 11.5774 23.5525 10.1687 23.008 8.85434C22.4636 7.53998 21.6656 6.34573 20.6597 5.33976C19.6537 4.33379 18.4594 3.53581 17.1451 2.99139C15.8307 2.44696 14.422 2.16675 12.9993 2.16675ZM17.5494 17.5501L11.916 14.0834V7.58342H13.541V13.2167L18.416 16.1417L17.5494 17.5501Z"
                  fill="#D0310E"
                />
              </svg>
              <h4>Guaranteed Delivery</h4>
            </span>
            <p>
              We are conscious of the relationship between time and money and
              utilize our resources optimally to meet your delivery needs. Our
              service commitment to you is hassle-free delivery. We are large
              enough to efficiently handle deliveries for large corporations yet
              small enough to deploy innovative delivery solutions that help
              small businesses.
            </p>
          </div>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default AboutUs;
