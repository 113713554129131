import { Outlet,NavLink  } from "react-router-dom";
import { Fragment, useContext} from "react";
import { useNavigate } from "react-router-dom";
import {AuthContext} from "../Context/context"
import "../Css/Inventory.css";


const NavLinkLCss = ({ isActive }) => {
  return {
    background: isActive ? "#1f4798" : "transparent",
  };
};

const InventoyAdmin = () => {
  const {user} = useContext(AuthContext)
  const navigate = useNavigate();
  const username = user.email.split('@')[0];
  return (
    <Fragment>
      <div className="upper">
        <div className="islogedin">
          <i className="fa-regular fa-bell"></i>
          <span>
            <i className="fa-regular fa-user"></i>
            <p style={{fontFamily: "Montserrat"}}>
            {username}
            </p>
          </span>
        </div>
      </div>
      <div className="inventory-admin">
        <img
          src="https://i.ibb.co/zRW2Jp5/Layer-1-2x.png"
          alt=""
          onClick={() => navigate("/ActiveDispatch")}
        />
        <div className="sidebar-nav"> 
          <NavLink to="/ActiveDispatch" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
            Active Dispatch
          </NavLink>
          <NavLink to="/InventoryDriver" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
            Drivers
          </NavLink>
          <NavLink to="/AssignmentUtilityDispatch" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
          Trips
          </NavLink>
          <NavLink to="/CreateCategory" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
            Create Category
          </NavLink>
          <NavLink to="/CreatePackages" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
          Inventories
          </NavLink>
          <NavLink to="/LogsSet" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
          Settings
          </NavLink>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default InventoyAdmin;
