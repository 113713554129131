import { Link, Outlet, useNavigate } from "react-router-dom";
import { Fragment, useContext } from "react";
import { AuthContext } from "../Context/context";
import IsLogginDropDwon from "../Pages/IsLoggedInDropDown";
import "../Css/Driveradmin.css";

const DriverAdmin = () => {
  const navigate = useNavigate();
  const {
    isMobile,
    setIsmobile,
    isLoggedIn,
    isLoggedInDrop,
    setisLoggedInDrop,
    LogOut,
    user
  } = useContext(AuthContext);
  const username = user.email.split('@')[0];
  return (
    <Fragment>
      <div className="driver-admin">
        <div className="nav">
          <div className="mobile-menu" onClick={() => setIsmobile(!isMobile)}>
            {isMobile ? (
              <i className="fa-solid fa-xmark"></i>
            ) : (
              <i className="fa-solid fa-bars"></i>
            )}
          </div>
          <img
            src="https://i.ibb.co/7NJgC5W/Layer-1.png"
            alt=""
            onClick={() => navigate("/DriverHome")}
          />

        </div>
          {isMobile && (
            <div className={`nav-links ${isMobile ? "mobile-link active" : ""}`}>
              <Link to="/ChangePassword" onClick={() => setIsmobile(false)}>Change Password</Link>
              <button  onClick={() => {
                LogOut()
                setIsmobile(false)
              }}>Log out</button>
            </div>
          )}

        {isLoggedIn && (
          <div className="islogedin">
            <i className="fa-regular fa-bell"></i>
            <span>
              <i className="fa-regular fa-user"></i>
              <p style={{fontFamily: "Montserrat"}}>
              {username}
              </p>
            </span>
            <i
              className="fa-solid fa-sort-down"
              onClick={() => setisLoggedInDrop(!isLoggedInDrop)}
            ></i>
          </div>
        )}
        {isLoggedInDrop && <IsLogginDropDwon />}
      </div>
      <Outlet />
    </Fragment>
  );
};

export default DriverAdmin;


