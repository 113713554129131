import { useState, useContext } from "react";
import { AuthContext } from "../Context/context";
import Spinner from "../Spinner/Spinner";
import "../Css/Auth.css";

const defaultForm = {
  old_password: "",
  new_password: "",
};

const ResetPass = () => {
  const { token, isPassWord, setIsPassword, setPass} = useContext(AuthContext);
  const [formField, setFormField] = useState(defaultForm);
  const { old_password, new_password } = formField;
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField({ ...formField, [name]: value });
    setError(null)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Process/ChangePassword`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formField),
        }
      );
      const resData = await res.json();

      if (!res.ok) {
        if (resData.status === "error" && resData.results) {
          setError(resData.message);
        } else {
          throw new Error(resData.message);
        }
      } else {
        setError(null);
        setFormField(defaultForm)
        setSuccessMessage(resData.message);
        setTimeout(() => {
            setSuccessMessage("")
            setPass(false)
        }, 3000);
      }
    } catch (error) {
      setError(error.message || "Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswords = () => {
    setIsPassword(!isPassWord);
  };

  return (
    <div className="auth-container">
      <span>
      <i className="fa-solid fa-arrow-left" onClick={() => setPass(false)}></i>
      </span>
      <h2>Change password</h2>
      <form onSubmit={handleSubmit}>
        <div className="others-card">
          <label>
            Old Password
            <span>
              <input
                type={isPassWord ? "text" : "password"}
                name="old_password"
                value={old_password}
                onChange={handleChange}
                required
              />
              <i
                className="fa-solid fa-eye-slash"
                onClick={handlePasswords}
              ></i>
            </span>
          </label>
          <label>
            New Password
            <span>
              <input
                type={isPassWord ? "text" : "password"}
                name="new_password"
                value={new_password}
                onChange={handleChange}
                required
              />
              <i
                className="fa-solid fa-eye-slash"
                onClick={handlePasswords}
              ></i>
            </span>
          </label>
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? <Spinner /> : "Change Password"}
        </button>
        {error && <p style={{color:"red", margin:"auto", fontFamily:" Montserrat"}}>{error}</p>}
        {successMessage && <p style={{color:"green", margin:"auto", fontFamily:" Montserrat"}}>{successMessage}</p>}
      </form>
    </div>
  );
};

export default ResetPass;
