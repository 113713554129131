import React, { useState, useEffect, useContext } from "react";
import Spinner from "../Spinner/Spinner";
import { useParams } from "react-router-dom";
import { AuthContext } from "../Context/context";

const defaultForm = {
  senders_name: "",
  senders_phone_number: "",
  senders_email: "",
  senders_address_1: "",
  senders_address_2: "",
  senders_zip_code: "",
  receivers_name: "",
  receivers_phone_number: "",
  receivers_email: "",
  receivers_address_1: "",
  receivers_address_2: "",
  receivers_zip_code: "",
  senders_state: "",
  receivers_state: "",
  senders_state_identifier: "",
  receivers_state_identifier: "",
};

const UpdateShippingInfoForm = ({ handleCancelShipping }) => {
  const { token } = useContext(AuthContext);
  const { id } = useParams();

  const [formField, setFormField] = useState(defaultForm);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successfully, setSuccefully] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

   
    const requiredFields = ['senders_name', 'receivers_name'];
    const isFormValid = requiredFields.every(field => formField[field].trim() !== '');

    if (!isFormValid) {
      setError('Please fill out all required fields.');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Update/Packages/${id}/ShippingInformation`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formField),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message);
      }
      console.log(data.shipping_information)

      setLoading(false);
      setError(null);
      setSuccefully("Shipping information updated successfully")
      setTimeout(() => {
        setSuccefully(null)
      }, 3000);
    } catch (error) {
      setLoading(false);
      console.error("Error updating shipping information:", error.message);
      setError("An error occurred while updating shipping information.");
    }
  };
 

  useEffect(() => {
    setLoading(false)
    const fetchShippingInfo = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Package/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const responseData = await response.json();

        if (!response.ok) {
          throw new Error(responseData.message);
        }

        console.log("data by id", responseData.results);

        const packageData = responseData.results;

        const shippingInfo = packageData.shipping_information || {};
        const senderInfo = shippingInfo.sender || {};
        const receiverInfo = shippingInfo.receiver || {};

        const senderState = senderInfo.state || {};
        const receiverState = receiverInfo.state || {};

        setFormField((prevForm) => ({
          ...prevForm,
          senders_name: senderInfo.name || "",
          senders_phone_number: senderInfo.phone_number || "",
          senders_email: senderInfo.email || "",
          senders_address_1: senderInfo.address_1 || "",
          senders_address_2: senderInfo.address_2 || "",
          senders_zip_code: senderInfo.zip_code || "",
          receivers_name: receiverInfo.name || "",
          receivers_phone_number: receiverInfo.phone_number || "",
          receivers_email: receiverInfo.email || "",
          receivers_address_1: receiverInfo.address_1 || "",
          receivers_address_2: receiverInfo.address_2 || "",
          receivers_zip_code: receiverInfo.zip_code || "",
          senders_state: senderState.state_label || "",
          receivers_state: receiverState.state_label || "",
          senders_state_identifier: senderState.identifier || "",
          receivers_state_identifier: receiverState.identifier || "",
        }));
      } catch (error) {
        console.error("Error fetching shipping information:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchShippingInfo();
  }, [id, token]);

 

  const handleCancel = () => {
    console.log("Update cancelled");
    handleCancelShipping();
  };

  return (
    <div className="edit-details">
      <form onSubmit={handleSubmit}>
        <span>
          <label>
            Sender's Name:
            <input
              type="text"
              name="senders_name"
              value={formField.senders_name}
              onChange={handleChange}
            />
          </label>
          <label>
            Receiver's Name:
            <input
              type="text"
              name="receivers_name"
              value={formField.receivers_name}
              onChange={handleChange}
            />
          </label>
        </span>
        <span>
          <label>
            Sender's Phone Number:
            <input
              type="tel"
              name="senders_phone_number"
              value={formField.senders_phone_number}
              onChange={handleChange}
            />
          </label>
          <label>
            Receiver's Phone Number:
            <input
              type="tel"
              name="receivers_phone_number"
              value={formField.receivers_phone_number}
              onChange={handleChange}
            />
          </label>
        </span>
        <span>
          <label>
            Sender's Email:
            <input
              type="email"
              name="senders_email"
              value={formField.senders_email}
              onChange={handleChange}
            />
          </label>
          <label>
            Receiver's Email:
            <input
              type="email"
              name="receivers_email"
              value={formField.receivers_email}
              onChange={handleChange}
            />
          </label>
        </span>

        <span>
          <label>
            Sender's Address Line 1:
            <input
              type="text"
              name="senders_address_1"
              value={formField.senders_address_1}
              onChange={handleChange}
            />
          </label>
          <label>
            Receiver's Address Line 1:
            <input
              type="text"
              name="receivers_address_1"
              value={formField.receivers_address_1}
              onChange={handleChange}
            />
          </label>
        </span>
        <span>
          <label>
            Sender's Address Line 2:
            <input
              type="text"
              name="senders_address_2"
              value={formField.senders_address_2}
              onChange={handleChange}
            />
          </label>
          <label>
            Receiver's Address Line 2:
            <input
              type="text"
              name="receivers_address_2"
              value={formField.receivers_address_2}
              onChange={handleChange}
            />
          </label>
        </span>
        <span>
          <label>
            Sender's Zip Code:
            <input
              type="text"
              name="senders_zip_code"
              value={formField.senders_zip_code}
              onChange={handleChange}
            />
          </label>
          <label>
            Receiver's Zip Code:
            <input
              type="text"
              name="receivers_zip_code"
              value={formField.receivers_zip_code}
              onChange={handleChange}
            />
          </label>
        </span>
        <span style={{display:"none"}}>
          <label>
            Sender's State:
            <input
              type="text"
              name="senders_state"
              value={formField.senders_state}
              onChange={handleChange}
            />
          </label>
          <label>
            Receiver's State:
            <input
              type="text"
              name="receivers_state"
              value={formField.receivers_state}
              onChange={handleChange}
            />
          </label>
        </span>
        <span style={{display:"none"}}>
          <label>
            Sender's State Identifier:
            <input
              type="text"
              name="senders_state_identifier"
              value={formField.senders_state_identifier}
              onChange={handleChange}
            />
          </label>
          <label>
            Receiver's State Identifier:
            <input
              type="text"
              name="receivers_state_identifier"
              value={formField.receivers_state_identifier}
              onChange={handleChange}
            />
          </label>
        </span>
        <span>
          <div className="pkg-update">
            <button type="button" onClick={handleCancel}>
              Cancel
            </button>
            <button type="submit">
              {loading ? <Spinner /> : "Update Shipping"}
            </button>
          </div>
          {error && <p>{error}</p>}
          {successfully && <p style={{color: "green"}}>{successfully}</p>}
        </span>
      </form>
    </div>
  );
};

export default UpdateShippingInfoForm;



