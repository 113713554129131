import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Faqs from "../Pages/Faqs";
import { Fragment, useState, useContext } from "react";
import { AuthContext } from "../Context/context";
import Spinner from "../Spinner/Spinner";
import formatDateTime from "../utils/FromatDate";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../assets/iconoir_search.svg";
import { ReactComponent as CircleIcon } from "../assets/Vector.svg";
import { ReactComponent as StackIcon } from "../assets/heroicons_square-3-stack-3d-20-solid.svg";
import { ReactComponent as ClockIcon } from "../assets/mdi_clock.svg";
import { ReactComponent as ScrollIcon } from "../assets/Property 1=scroll-down-svgrepo-com 1.svg";
import {ReactComponent as ForumIcon} from "../assets/forum.svg"
import Carousel from "../Carousel/carousel";
import { StateContext } from "../Context/states-context";
import Chatus from "../Pages/Chatus";
import "../Css/Home.css";

const Home = () => {
  const {chatBoat, setChatboat} = useContext(StateContext)
  const [trackingId, setTrackingId] = useState("");
  const [trackingResult, setTrackingResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const { token } = useContext(AuthContext);

  const handleTracking = async () => {
    if (!trackingId.trim()) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Track/Dispatch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            tracking_id: trackingId,
          }),
        }
      );

      const resData = await response.json();
      console.log(resData);
      if (response.ok) {
        setError(null);
        setTrackingResult(resData.results);
      } else {
        throw new Error(resData.message);
      }
    } catch (error) {
      setError(error.message);
      console.error("Error while tracking shipment:", error);
      setTrackingResult(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setTrackingResult(null);
    setTrackingId("");
  };

  return (
    <Fragment>
      <Helmet>
      <title>Home | Doorstep</title>
        <meta name="description" content="Home page of Doorstep, your trusted logistics partner." />
        <link rel="icon" href="/public/favicon.ico" />
       
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:url" content="https://www.doorstepng.com/" />

  
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta name="twitter:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta name="twitter:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="instapp:site_name" content="Doorstep" />
  <meta property="instapp:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="instapp:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="instapp:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />

  
  <meta property="tiktok:card" content="summary_large_image" />
  <meta property="tiktok:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="tiktok:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="tiktok:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:url" content="https://www.doorstepng.com/" />
      </Helmet>
      {trackingResult && (
        <div className="tracking-result">
          <div className="tracking-status">
            <div className="track-wrap">
              <span>
                <h2>Tracking Shipment</h2>
                <i className="fa-solid fa-x" onClick={handleClose}></i>
              </span>
            </div>
            <div className="info">
              <span>
                <h3>Tracking ID</h3>
                <p>{trackingResult.tracking_id}</p>
              </span>
              <span>
                <h3>Status</h3>
                <p>{trackingResult.status}</p>
              </span>
              <span>
                <h3>Description</h3>
                <p>{trackingResult.package.name}</p>
              </span>
              <span>
                <h3>Quantity</h3>
                <p>{trackingResult.package.quantity}</p>
              </span>
            </div>
            <h3>Travel History</h3>
            <div className="travel-his">
              {trackingResult.update_history.map((update, index) => (
                <li key={index}>
                  <span>
                    <h3>{update.state}</h3>
                    <p>{update.city}</p>
                  </span>
                  {formatDateTime(update.date_time)}
                </li>
              ))}
            </div>
          </div>
        </div>
      )}

      <div className="home-container">
        <div className="home-banner">
          <div className="box">
            <div className="header">
              <h1>WE'LL TAKE IT FROM HERE</h1>
            </div>
            <p>
              We handle all the details, from pickup to delivery, so you can
              focus on your business
            </p>
            <div className="tracking-field">
              <Link to="/Login">Sign in</Link>
              <span>
                <input
                  type="text"
                  placeholder="Input Tracking ID"
                  value={trackingId}
                  onChange={(e) => {
                    setTrackingId(e.target.value);
                    setError(null);
                  }}
                  required
                />
                <SearchIcon onClick={handleTracking} />
              </span>
              {isLoading && <Spinner />}
              {error && <p>{error}</p>}
            </div>
            <ScrollIcon />
          </div>
          <div className="box-slider">
            <Carousel />
          </div>
        </div>
        <div className="brand-logo">
        {chatBoat && <Chatus/>}
          <div className="forum" onClick={() => setChatboat(!chatBoat)}>
          <ForumIcon/>
          </div>
        </div>
        <div className="who-are-we-container">
          <div className="box">
            <h2>WHO ARE WE</h2>
            <p>
              welcome to Doorstep, your trusted partner in efficient and
              reliable logistics solutions. With a commitment to excellence and
              a passion for seamless supply chain management, we take pride in
              delivering outstanding services tailored to meet your unique
              needs. Whether you're a small business or a multinational
              corporation, we are here to streamline your logistics processes
              and optimize your operations.
            </p>
            <button onClick={() => navigate("/AboutUs")}>Read more</button>
          </div>
          <div className="box-play">
            <img
              src="https://i.ibb.co/483np8x/Frame-1171277879-2x.png"
              alt=""
            />
          </div>
        </div>
        <div className="seamless">
          <h1> SEAMLESS DELIVERY SERVICES</h1>
          <div className="wrapper">
            <div className="box">
              <div className="inner">
                <span>
                  <img
                    src="https://i.ibb.co/r2Ftn64/mdi-box-outline-2x.png"
                    alt=""
                  />
                  <h4>Ship Now</h4>
                </span>
                <p>Request Pickup/Delivery and Xpress drop-off</p>
              </div>
            </div>
            <div className="box">
              <div className="inner">
                <span>
                  <img
                    src="https://i.ibb.co/Svy78gQ/mdi-airplane-settings-2x.png"
                    alt=""
                  />
                  <h4>Overseas Shipping</h4>
                </span>
                <p>
                  Ship from UK/USA to Nigeria & Export to 230 plus Locations
                  Worldwide
                </p>
              </div>
            </div>
            <div className="box">
              <div className="inner">
                <span>
                  <img
                    src="https://i.ibb.co/QcMRbcV/ph-receipt-bold-2x.png"
                    alt=""
                  />
                  <h4>Get a Quick Qoute</h4>
                </span>
                <p>
                  Calculate cost estimate for local & international shipments
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="why-us">
          <div className="box">
            <img src="https://i.ibb.co/VY6Q7Rw/Vector-3.png" alt="" />
          </div>
          <h1>WHY CHOOSE US</h1>
          <div className="why-us-card">
            <div className="cards">
              <span>
                <CircleIcon />
                <h4>Latest Technology</h4>
              </span>
              <p>
                We understand the role of technology in providing an exceptional
                customer experience. For this reason, our processes, products
                and services are built around modern technology to help us
                deliver your shipments cheaper, safely and at the speed of
                light.
              </p>
            </div>
            <div className="cards">
              <span>
                <StackIcon />
                <h4>Social Commerce</h4>
              </span>
              <p>
                Pivotal to successful social commerce transactions is the
                ability for the end-user to receive items where and when needed.
                With an unmatched route network spanning urban and rural
                communalities, [company] is the solution to efficient last-mile
                delivery. As a merchant, you only have to think about selling
                your products while [company] delivers to your customers
                worldwide.
              </p>
            </div>
            <div className="cards">
              <span>
                <ClockIcon />
                <h4>Guanranteed Delivery</h4>
              </span>
              <p>
                We are conscious of the relationship between time and money and
                utilize our resources optimally to meet your delivery needs. Our
                service commitment to you is hassle-free delivery. We are large
                enough to efficiently handle deliveries for large corporations
                yet small enough to deploy innovative delivery solutions that
                help small businesses.
              </p>
            </div>
          </div>
        </div>
        <div className="blog-container">
          <span>
            <h1>DON'T MISS OUR LATEST NEWS AND ARTICLES</h1>
            <button>View more</button>
          </span>
          <div className="line"></div>
          <div className="card-container">
            <div className="card">
              <img src="https://i.ibb.co/vwYpxTf/Frame-34.png" alt="" />
              <p>Headline Text</p>
              <span>
                Lorem ipsum dolor sit amet consectetur. Vel feugiat enim
                vestibulum pellentesque lectus ipsum habitant. Purus nisnf
              </span>
            </div>
            <div className="card">
              <img src="https://i.ibb.co/944Q0fm/Frame-34-1.png" alt="" />
              <p>Headline Text</p>
              <span>
                Lorem ipsum dolor sit amet consectetur. Vel feugiat enim
                vestibulum pellentesque lectus ipsum habitant. Purus nisnf
              </span>
            </div>
            <div className="card">
              <img src="https://i.ibb.co/ccGRx7n/Frame-34-2.png" alt="" />
              <p>Headline Text</p>
              <span>
                Lorem ipsum dolor sit amet consectetur. Vel feugiat enim
                vestibulum pellentesque lectus ipsum habitant. Purus nisnf
              </span>
            </div>
          </div>
          <button>View more</button>
        </div>
        <Faqs />
      </div>
    </Fragment>
  );
};

export default Home;
