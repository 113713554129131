import { useContext } from "react";
import { AuthContext } from "../Context/context";
import { useNavigate } from "react-router-dom";
import "../Css/Driveradmin.css"



const IsLogginDropDwon = () => {
    const {LogOut, setisLoggedInDrop} = useContext(AuthContext)
    const navigate = useNavigate()
    const handleSetting = () => {
        navigate('/ChangePassword')
        setisLoggedInDrop(false)
    }

    const handleLogout = () => {
        LogOut()
        navigate('/')
        setisLoggedInDrop(false)
    }
    return ( 
        <div className="islogin-drop">
            <span>
            <i className="fa-solid fa-user"></i>
            <p onClick={handleSetting}>Change password</p>
            </span>
            <span>
            <i className="fa-solid fa-right-from-bracket"></i>
            <p onClick={handleLogout}>Log Out</p>
            </span>
        </div>
     );
}
 
export default IsLogginDropDwon;