import { useNavigate } from "react-router-dom";
import useFormValidationAddDrivers from "../Hooks/useFormValidationAddDrivers";
import Spinner from "../Spinner/Spinner";
import '../Css/super.css'

const AddDrivers = () => {
  const {
    formData,
    errors,
    isLoading,
    handleInputChange,
    resDataError,
    handleSubmit,
    successMessage,
  } = useFormValidationAddDrivers();

  const navigate = useNavigate();

  return (
    <div className="add-driver-page">
      <span>
        <i
          className="fa-solid fa-arrow-left"
          onClick={() => navigate("/Driver")}
        ></i>
        <p>Add Driver</p>
      </span>
      <div className="add">
        <form onSubmit={handleSubmit}>
          <div className="input">
            <label>
              First Name
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleInputChange}
                required
              />
              {errors.first_name && <span>{errors.first_name}</span>}
            </label>

            <label>
              Last Name
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleInputChange}
                required
              />
              {errors.last_name && <span>{errors.last_name}</span>}
            </label>

            <label>
              Email
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              {errors.email && <span>{errors.email}</span>}
            </label>
            
            <div className="phone">
              <label>
                Phone Number
                <span>
                  <select
                    name="country_code"
                    value={formData.country_code || "+234"}
                    onChange={handleInputChange}
                    required
                    >
                    <option value="234">+234</option>
                  </select>
                  <input
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                    required
                    />
                    </span>
                {errors.phone_number && <span>{errors.phone_number}</span>}
              </label>
            </div>
            <label>
              Address
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              />
              {errors.address && <span>{errors.address}</span>}
            </label>
            <label>
              Driver's License Number
              <input
                type="text"
                name="drivers_license_number"
                value={formData.drivers_license_number}
                onChange={handleInputChange}
                required
              />
              {errors.drivers_license_number && (
                <span>{errors.drivers_license_number}</span>
              )}
            </label>

            <label>
              Vehicle Type
              <input
                type="text"
                name="vehicle_type"
                value={formData.vehicle_type}
                onChange={handleInputChange}
                required
              />
              {errors.vehicle_type && <span>{errors.vehicle_type}</span>}
            </label>

            <label>
              Vehicle Plate Number
              <input
                type="text"
                name="vehicle_plate_number"
                value={formData.vehicle_plate_number}
                onChange={handleInputChange}
                required
              />
              {errors.vehicle_plate_number && (
                <span>{errors.vehicle_plate_number}</span>
              )}
            </label>

            <label>
              Vehicle Registration Number
              <input
                type="text"
                name="vehicle_registration_number"
                value={formData.vehicle_registration_number}
                onChange={handleInputChange}
                required
              />
              {errors.vehicle_registration_number && (
                <span>{errors.vehicle_registration_number}</span>
              )}
            </label>
          </div>
          {resDataError && (
            <span style={{ margin: "auto", color: "red" }}>{resDataError}</span>
          )}
          {successMessage && (
            <div style={{ margin: "auto", color: "green", fontFamily:"Montserrat" }}>
              {successMessage}
            </div>
          )}
          <button>{isLoading ? <Spinner /> : "Send code"}</button>
        </form>
      </div>
    </div>
  );
};

export default AddDrivers;
