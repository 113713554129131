import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

const defaultForm = {
  email: "",
  branch: "",
};

const AddAdmin = () => {
  const [formField, setFormField] = useState(defaultForm);
  const [isSubmited, setSubmit] = useState(false);
  const { email, branch } = formField;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField({ ...formField, [name]: value });
  };

  const handleSbmit = (e) => {
    e.preventDefault()
    console.log(formField);
    setFormField(defaultForm)
    setSubmit(true);
  };
  const navigate = useNavigate();
  return (
    <Fragment>
      {isSubmited && <div className="confirmation">
        <img src="https://i.ibb.co/kxKyFZW/mail-send-svgrepo-com-1-2x.png" alt="" />
        <p>Mail has been sent to <span>joshuaadams@gmail.com</span></p>
        <button onClick={() => setSubmit(false)}>Back</button>
        </div>}
      <div className="add-addmin-page">
        <span>
          <i
            className="fa-solid fa-arrow-left"
            onClick={() => navigate("/Admins")}
          ></i>
          <p>Add Admin</p>
        </span>

        <div className="add">
          <form onSubmit={handleSbmit}>
            <label>
              Enter Email Address
              <input type="email" required onChange={handleChange} name="email" value={email} />
            </label>

            <label>
              Branch
              <select name="branch" value={branch} onChange={handleChange}>
                <option value="">Select Branch</option>
              </select>
            </label>
            <button>Send Code</button>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default AddAdmin;
