import { useState, useContext } from "react";
import { AuthContext } from "../Context/context";
import { useNavigate } from "react-router-dom";

const useFormValidationLogin = () => {
  const { Login } = useContext(AuthContext);
  const navigate = useNavigate();

  const defaultFormData = {
    email: "",
    password: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState({});
  const [resDataError, setResDataError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const validatePassword = (password) => {
    const errors = [];

    if (password.length < 6) {
      errors.push("Password must be at least 6 characters long");
    }

    return errors.length === 0 ? null : errors;
  };

  const handleInputChange = (e) => {
    setErrors({});
    setResDataError(null);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    const passwordErrors = validatePassword(formData.password);
    if (passwordErrors) {
      newErrors.password = passwordErrors;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setIsLoading(true);

      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Authenticate/Staff/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        const resData = await res.json();

        if (!res.ok) {
          setResDataError(resData.message);
          throw new Error(resData.message);
        }

        const user = {
          email: formData.email,
          token: resData.results.access_credentials.token,
          user_role: resData.results.user_role,
        };


        Login(user);
        if (user.user_role.role_name_slug === "super-admin") {
          navigate("/OverView");
        } else if (user.user_role.role_name_slug === "inventory-manager") {
          navigate("/ActiveDispatch");
        } else if (user.user_role.role_name_slug === "driver") {
          navigate("/DriverHome");
        } else {
          navigate("/");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    formData,
    errors,
    resDataError,
    isLoading,
    handleInputChange,
    handleSubmit,
  };
};

export default useFormValidationLogin;
