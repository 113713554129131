import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "./Context/context";




const styles = {
  container: {
    textAlign: "center",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    
  },
  header: {
    fontSize: "2em",
  },
  paragraph: {
    fontSize: "1.2em",
  },
  link: {
    fontSize: "1.2em",
    textDecoration: "none",
    color: "blue",
    margin: "10px",
  },
};

const NotFound = () => {
  const { isLoggedIn, LogOut } = useContext(AuthContext);

  useEffect(() => {
    if (isLoggedIn) {
      LogOut();
    }
  }, [isLoggedIn, LogOut]);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>404 - Not Found</h2>
      <p style={styles.paragraph}>
        The page you are looking for does not exist.
      </p>
      {isLoggedIn ? (
        <Link to="/" style={styles.link}>
          Go to the Home Page
        </Link>
      ) : (
        <p style={styles.paragraph}>
          You have been logged out. Please{" "}
          <Link to="/Login" style={styles.link}>
            log in
          </Link>{" "}
          again.
        </p>
      )}
    </div>
  );
};

export default NotFound;



// const NotFound = () => {
//   const {LogOut} = useContext(AuthContext)
//   useEffect(() => {
//     LogOut()
//   })
// }
 
// export default NotFound;