import React, { useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../Context/context";
import { useQuery } from "react-query";
import "../Css/super.css";

const Logistics = () => {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchManagers = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/InventoryManagers`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    console.log("InventoryManagers", data);

    if (!response.ok) {
      throw new Error(data.message);
    }

    return data.results;
  };

  const fetchSuspensions = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Suspensions/Staff`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: currentPage,
            per_page: itemsPerPage,
          },
        }
      );

      const data = await response.json();
      console.log("suspension", data);

      if (!response.ok) {
        throw new Error(data.message);
      }

      return data.results.suspensions;
    } catch (error) {
      console.error("Error fetching suspensions:", error);
      throw error;
    }
  };

  const {
    data: managers,
    isLoading: isManagersLoading,
    isError: isManagersError,
  } = useQuery("managers", fetchManagers, {
    enabled: !!token,
  });

  const {
    data: suspensions,
    isLoading: isSuspensionsLoading,
    isError: isSuspensionsError,
    isRefetching,
  } = useQuery(
    ["suspensions", { page: currentPage, per_page: itemsPerPage }],
    fetchSuspensions,
    {
      enabled: !!token,
    }
  );

  const isInventoryManagerSuspended = (managerId) => {
    return (
      suspensions &&
      suspensions.length > 0 &&
      suspensions.some(
        (suspension) =>
          suspension.inventory_manager &&
          suspension.inventory_manager.identifier === managerId &&
          suspension.is_active
      )
    );
  };

  const filteredManagers = managers
  ? managers.filter((manager) =>
      manager.full_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
  : [];

  if (isManagersLoading || isSuspensionsLoading) {
    return "Loading....";
  }

  if (isManagersError || isSuspensionsError) {
    return <p>Error fetching data</p>;
  }

  return (
    <div className="logistics-container">
      <div className="add-officer">
        <span>
          <i className="fa-solid fa-magnifying-glass"></i>
          <input
            type="search"
            placeholder="Search manager by name...."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </span>
        <button onClick={() => navigate("/AddOfficers")}>Add Officer</button>
      </div>
      <div className="package-table">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Address</th>
              <th>Details</th>
              <th>Status</th>
            </tr>
          </thead>
          {filteredManagers.length === 0 ? (
            <tbody>
              <tr>
                <td colSpan="6">No inventory managers found.</td>
              </tr>
            </tbody>
          ) : (
            <tbody>
              {filteredManagers.map((manager) => (
                <tr key={manager.identifier}>
                  <td>{manager.identifier.slice(0, 12).replace(/-/g, '').toUpperCase()}</td>
                  <td>{manager.full_name}</td>
                  <td>{manager.email}</td>
                  <td>{manager.address}</td>
                  <td>
                    <Link to={`/manager-details/${manager.identifier}`}>
                      Details
                    </Link>
                  </td>
                  <td>
                    {suspensions &&
                    isInventoryManagerSuspended(manager.identifier) ? (
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: "red" }}
                      ></i>
                    ) : (
                      <i
                        className="fa-solid fa-circle"
                        style={{ color: "green" }}
                      ></i>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      <div className="pagination-controls">
        <div className="shows">
          Showing {currentPage * itemsPerPage - itemsPerPage + 1} -{" "}
          {Math.min(currentPage * itemsPerPage, filteredManagers.length)} of{" "}
          {filteredManagers.length} Managers
        </div>
        <div className="pagination-buttons">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1 || isRefetching}
          >
            {"<"}
          </button>
          {Array.from(
            { length: Math.ceil(filteredManagers.length / itemsPerPage) },
            (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                disabled={currentPage === index + 1 || isRefetching}
              >
                {index + 1}
              </button>
            )
          )}
          <button
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(
                  prev + 1,
                  Math.ceil(filteredManagers.length / itemsPerPage)
                )
              )
            }
            disabled={
              currentPage ===
                Math.ceil(filteredManagers.length / itemsPerPage) ||
              isRefetching
            }
          >
            {">"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Logistics;



