import { useContext, useEffect, useState, useCallback } from "react";
import { ShippingContext } from "../Context/ShippingContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/context";
import "../Css/Shipping.css";

const ShippingAddress = () => {
  const navigate = useNavigate();

  const {
    senderInfo,
    setSenderInfo,
    receiverInfo,
    setReceiverInfo,
    saveShippingInfo,
    error,
    setError,
    setSendersStateIdentifier,
    setReceiversStateIdentifier,
  } = useContext(ShippingContext);
  const { token } = useContext(AuthContext);
  const [states, setStates] = useState(null);

  useEffect(() => {
    const sendHttpReq = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/States`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (!res.ok) {
          throw new Error(resData.message);
        }
        setStates(resData);
      } catch (error) {
        setError(error.message);
        console.log(error.message);
      }
    };
    sendHttpReq();
  }, [token, setError]);
  
  const fetchStateIdentifier = useCallback(async (state, infoSetter, isSender) => {
    try {
      const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/States`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      const resData = await res.json();
  
      if (!res.ok) {
        throw new Error(resData.message);
      }
  
      const stateData = resData.results.find((s) => s.state_label === state);
  
      if (!stateData) {
        throw new Error("State Data not found");
      }
  
      const stateIdentifier = stateData.identifier;
  
      // Save state identifier to local storage with a key based on the sender or receiver
      const storageKey = isSender ? "senders_state_identifier" : "receivers_state_identifier";
      localStorage.setItem(storageKey, stateIdentifier);
  
      console.log(`${state} State Identifier:`, stateIdentifier);
      console.log(`Stored in local storage with key: ${storageKey}`);
  
      // Retrieve state identifier from local storage (added for verification)
      const storedStateIdentifier = localStorage.getItem(storageKey);
      console.log(`Retrieved from local storage: ${storedStateIdentifier}`);
  
      infoSetter({
        ...infoSetter,
        [`${infoSetter.name}_state_identifier`]: stateIdentifier,
      });
  
      console.log(`${state} State Identifier:`, stateIdentifier);
    } catch (error) {
      setError(error.message);
      console.error(error.message);
    }
  }, [token, setError]);
  
  
  

  useEffect(() => {
    if (senderInfo.senders_state) {
      fetchStateIdentifier(
        senderInfo.senders_state,
        setSendersStateIdentifier,
        true, // This is for the sender
      );
    }
  
    if (receiverInfo.receivers_state) {
      fetchStateIdentifier(
        receiverInfo.receivers_state,
        setReceiversStateIdentifier,
        false, // This is for the receiver
      );
    }
  }, [
    senderInfo.senders_state,
    receiverInfo.receivers_state,
    token,
    setSenderInfo,
    setReceiverInfo,
    setSendersStateIdentifier,
    setReceiversStateIdentifier,
    setError,
    fetchStateIdentifier,
  ]);
  

  const handleProceed = (e) => {
    e.preventDefault();

    if (
      !senderInfo.senders_name ||
      !senderInfo.senders_phone_number ||
      !senderInfo.senders_address_1 ||
      !senderInfo.senders_state ||
      !senderInfo.senders_zip_code ||
      !receiverInfo.receivers_name ||
      !receiverInfo.receivers_phone_number ||
      !receiverInfo.receivers_address_1 ||
      !receiverInfo.receivers_state ||
      !receiverInfo.receivers_zip_code
    ) {
      setError(true);
    } else {
      const shippingInfo = {
        senderInfo,
        receiverInfo,
        
      };

      saveShippingInfo(shippingInfo);
      navigate("/ItemsInfo");
    }
  };
  

  return (
    <div className="shipping-container">
      <img
        src="https://i.ibb.co/hmKgjdq/nav-bar-nationwide-delivery-2x.png"
        alt=""
      />
      <h1>NATIONWIDE DELIVERY</h1>
      <p>Deliver to your doorstep Nationwide.</p>
      <form>
        <img src="https://i.ibb.co/XFxgqbs/Frame-100-2x.jpg" alt="" />
        <p>
          Simply use details in the format below as your shipping address when
          shipping from any store (physical or online). In cases where you are
          required to input a phone number, use your personal number please.
          This will enable Doorstep Logistics to receive items on your behalf
          for onward delivery.
        </p>
        <div className="sender-info">
          <p>Sender's info</p>
          <span>
            <label>
              Name
              <input
                type="text"
                name="senderName"
                value={senderInfo.senders_name}
                onChange={(e) =>
                  setSenderInfo({ ...senderInfo, senders_name: e.target.value })
                }
              />
            </label>
            <label>
              Phone Number
              <div className="phone">
                <select>
                  <option value="+234">+234</option>
                </select>
                <input
                  type="text"
                  name="senderPhone"
                  value={senderInfo.senders_phone_number}
                  onChange={(e) =>
                    setSenderInfo({
                      ...senderInfo,
                      senders_phone_number: e.target.value,
                    })
                  }
                />
              </div>
            </label>
          </span>
          <span>
            <label>
              Address Line 1
              <textarea
                placeholder="3 Taiwo Ishola Street, Lekki, Nigeria"
                name="senderAddressLine1"
                value={senderInfo.senders_address_1}
                onChange={(e) =>
                  setSenderInfo({
                    ...senderInfo,
                    senders_address_1: e.target.value,
                  })
                }
              ></textarea>
            </label>

            <label>
              Address Line 2
              <textarea
                placeholder="3 Taiwo Ishola Street, Lekki, Nigeria"
                name="senderAddressLine2"
                value={senderInfo.senders_address_2}
                onChange={(e) =>
                  setSenderInfo({
                    ...senderInfo,
                    senders_address_2: e.target.value,
                  })
                }
              ></textarea>
            </label>
          </span>

          <span>
            <label>
              State
              <select
                name="senderState"
                value={senderInfo.senders_state}
                onChange={(e) =>
                  setSenderInfo({
                    ...senderInfo,
                    senders_state: e.target.value,
                  })
                }
              >
                {states &&
                  states.results &&
                  states.results.map((state) => (
                    <option key={state.identifier} value={state.state_label}>
                      {state.state_label}
                    </option>
                  ))}
              </select>
            </label>
            <label>
              Zip Code
              <input
                type="text"
                name="senderZipCode"
                value={senderInfo.senders_zip_code}
                onChange={(e) =>
                  setSenderInfo({
                    ...senderInfo,
                    senders_zip_code: e.target.value,
                  })
                }
              />
            </label>
          </span>
          <span>
            <label>
              Email
              <input
                type="email"
                name="senderEmail"
                value={senderInfo.senders_email}
                onChange={(e) =>
                  setSenderInfo({
                    ...senderInfo,
                    senders_email: e.target.value,
                  })
                }
              />
            </label>
          </span>
        </div>
        <div className="sender-info">
          <p>Receiver's info</p>
          <span>
            <label>
              Name
              <input
                type="text"
                name="receiverName"
                value={receiverInfo.receivers_name}
                onChange={(e) =>
                  setReceiverInfo({
                    ...receiverInfo,
                    receivers_name: e.target.value,
                  })
                }
              />
            </label>
            <label>
              Phone Number
              <div className="phone">
                <select>
                  <option value="+234">+234</option>
                </select>
                <input
                  type="text"
                  name="receiverPhone"
                  value={receiverInfo.receivers_phone_number}
                  onChange={(e) =>
                    setReceiverInfo({
                      ...receiverInfo,
                      receivers_phone_number: e.target.value,
                    })
                  }
                />
              </div>
            </label>
          </span>
          <span>
            <label>
              Address Line 1
              <textarea
                placeholder="3 Taiwo Ishola Street, Lekki, Nigeria"
                name="receiverAddressLine1"
                value={receiverInfo.receivers_address_1}
                onChange={(e) =>
                  setReceiverInfo({
                    ...receiverInfo,
                    receivers_address_1: e.target.value,
                  })
                }
              ></textarea>
            </label>

            <label>
              Address Line 2
              <textarea
                placeholder="3 Taiwo Ishola Street, Lekki, Nigeria"
                name="receiverAddressLine2"
                value={receiverInfo.receivers_address_2}
                onChange={(e) =>
                  setReceiverInfo({
                    ...receiverInfo,
                    receivers_address_2: e.target.value,
                  })
                }
              ></textarea>
            </label>
          </span>
          <span>
            <label>
              State
              <select
                name="receiverState"
                value={receiverInfo.receivers_state}
                onChange={(e) =>
                  setReceiverInfo({
                    ...receiverInfo,
                    receivers_state: e.target.value,
                  })
                }
              >
                {states &&
                  states.results &&
                  states.results.map((state) => (
                    <option key={state.identifier} value={state.state_label}>
                      {state.state_label}
                    </option>
                  ))}
              </select>
            </label>
            <label>
              Zip Code
              <input
                type="text"
                name="receiverZipCode"
                value={receiverInfo.receivers_zip_code}
                onChange={(e) =>
                  setReceiverInfo({
                    ...receiverInfo,
                    receivers_zip_code: e.target.value,
                  })
                }
              />
            </label>
          </span>
          <span>
            <label>
              Email
              <input
                type="email"
                name="receiverEmail"
                value={receiverInfo.receivers_email}
                onChange={(e) =>
                  setReceiverInfo({
                    ...receiverInfo,
                    receivers_email: e.target.value,
                  })
                }
              />
            </label>
          </span>
        </div>
        {error && (
          <p style={{ color: "red" }}>Please fill in all the required fields</p>
        )}
        <button onClick={handleProceed}>Proceed</button>
      </form>
    </div>
  );
};

export default ShippingAddress;
