import React, { useState, Fragment, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { AuthContext } from "../Context/context";
import Spinner from "../Spinner/Spinner";
import "../Css/super.css";

const ManagerDetails = () => {
  const { token } = useContext(AuthContext);
  const { id } = useParams();
  const [suspend, setSuspend] = useState(false);
  const [successMessageDeactivate, setSuccessMessageDeactivate] =
    useState(null);
  const [successMessageEndSuspension, setSuccessMessageEndSuspension] =
    useState(null);
  const navigate = useNavigate();
  const [endSuspendCon, setEndSuspenCon] = useState(false);
  const [shouldNavigateToLogistics, setShouldNavigateToLogistics] =
    useState(false);
  const [deactivateConfirmation, setDeactivateConfirmation] = useState(false);

  // Query to fetch manager details
  const {
    data: managerDetails,
    isLoading,
    isError,
  } = useQuery(["managerDetails", id], async () => {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/InventoryManagers/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const resData = await res.json();

    if (!res.ok) {
      throw new Error(resData.message);
    }

    return resData.results;
  });

  // Query to fetch suspensions
  const { data: suspensionsData } = useQuery(["suspensions", id], async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Suspensions/Staff`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(responseData.message);
    }

    return responseData.results.suspensions;
  });

  // useEffect(() => {
  //   if (shouldNavigateToLogistics) {
  //     navigate("/Logistics");
  //   }
  // }, [shouldNavigateToLogistics, navigate]);
  useEffect(() => {
    if (shouldNavigateToLogistics) {
      setTimeout(() => {
        navigate("/Logistics");
      }, 3000); // 3000 milliseconds = 3 seconds
    }
  }, [shouldNavigateToLogistics, navigate]);
  

  // Check if there is an active suspension for the manager
  const isManagerSuspended = suspensionsData?.some(
    (suspension) =>
      suspension.inventory_manager?.identifier === id && suspension.is_active
  );

  // Mutation to handle suspension
  const { mutate: suspendManager } = useMutation(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Suspend/InventoryManager`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          inventory_manager_identifier: id,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      console.error("Error suspending manager:", data);
      throw new Error(data.message || "Failed to suspend manager");
    }

    setSuccessMessageDeactivate(data.message);

    setSuspend(false);
    setShouldNavigateToLogistics(true); // Set the flag to trigger navigation
  });

  // Mutation to handle ending suspension
  const { mutate: endSuspension } = useMutation(async () => {
    const suspension = suspensionsData?.find(
      (suspension) =>
        suspension.inventory_manager &&
        suspension.inventory_manager.identifier === id &&
        suspension.is_active
    );

    if (!suspension) {
      console.error("Suspension not found");
      return;
    }

    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Process/End/Suspension`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          identifier: suspension.identifier,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      console.error("Error ending suspension:", data);
      throw new Error(data.message || "Failed to end suspension");
    }

    // Update state to show success message
    setSuccessMessageEndSuspension(data.message);
    setEndSuspenCon(false);
    setShouldNavigateToLogistics(true); // Set the flag to trigger navigation
  });

  // Mutation to handle deactivation
  const { mutate: deactivateManager } = useMutation(async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Deactivate/InventoryManager`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          inventory_manager_identifier: id,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      console.error("Error deactivating InventoryManager:", data);
      throw new Error(data.message || "Failed to deactivate InventoryManager");
    }
    setSuccessMessageDeactivate(data.message);
    // setDeactivateConfirmation(false)
    setShouldNavigateToLogistics(true); 
  });

  const handleEndSuspension = () => {
    endSuspension();
  };

  const handleDeactivate = () => {
    deactivateManager();
  };

  return (
    <Fragment>
      {suspend && (
        <div className="suspend-container">
          <h3>Are you sure you want to suspend this Manager?</h3>
          {isError && <p>{isError.message}</p>}
          <div className="suspend-form">
            <div className="sus-btn">
              <button
                onClick={() => {
                  setSuspend(false);
                  setSuccessMessageDeactivate(null);
                }}
              >
                Cancel
              </button>
              <button onClick={suspendManager}>
                {isLoading ? <Spinner /> : "Suspend Manager"}
              </button>
            </div>
          </div>
        </div>
      )}

      {endSuspendCon && (
        <div className="end-suspend-container">
          <h3>Are you sure you want to End suspension for this Manager?</h3>
          {successMessageEndSuspension && (
            <p className="success-message">{successMessageEndSuspension}</p>
          )}
          {isError && <p>{isError.message}</p>}
          <div className="endsuspend-form">
            <div className="endsus-btn">
              <button
                onClick={() => {
                  setEndSuspenCon(false);
                }}
              >
                Cancel
              </button>
              <button onClick={handleEndSuspension}>
                {isLoading ? <Spinner /> : " End Suspension"}
              </button>
            </div>
          </div>
        </div>
      )}

      {deactivateConfirmation && (
        <div className="deactivate-overlay">
          <h3>Are you sure you want to deactivate this Manager?</h3>
          <p>Please Note this action is irreversible.</p>
          {successMessageDeactivate && (
                <p className="success-message" style={{color: "green"}} >{successMessageDeactivate}</p>
              )}
          <div className="deactivate-form">
          <div className="deactivate-btn">
            <button onClick={() => setDeactivateConfirmation(false)}>
              Cancel
            </button>
            <button onClick={handleDeactivate}>Deactivate</button>
          </div>
          </div>
        </div>
      )}

      <div className="manager-details">
        <div className="add-officer">
          <p>Logistics Officer</p>
          <button onClick={() => navigate("/AddOfficers")}>Add Officer</button>
        </div>
        {isLoading ? (
          <Spinner />
        ) : (
          <div className="logistic-details">
            <div className="header-logs">
              <span>
                <i
                  className="fa-solid fa-arrow-left"
                  onClick={() => navigate("/Logistics")}
                ></i>
                <p>{id}</p>
              </span>
              {isManagerSuspended ? (
                <button onClick={() => setEndSuspenCon(true)}>
                  End Suspension
                </button>
              ) : (
                <button onClick={() => setSuspend(true)}>Suspend</button>
              )}
            </div>
            <div className="span">
              <span>
                <h2>First Name</h2>
                <p>{managerDetails.first_name}</p>
              </span>

              <span>
                <h2>Last Name</h2>
                <p>{managerDetails.last_name}</p>
              </span>

              <span>
                <h2>Email</h2>
                <p>{managerDetails.email}</p>
              </span>
              <span>
                <h2>Address</h2>
                <p>{managerDetails.address}</p>
              </span>

              <span>
                <h2>Phone Number</h2>
                <p>{managerDetails.phone_number}</p>
              </span>

              <button onClick={() => setDeactivateConfirmation(true)}>
                Deactivate Manager
              </button>
            </div>
          </div>
        )}
        {isError && <p>Error: {isError.message}</p>}
      </div>
    </Fragment>
  );
};

export default ManagerDetails;
