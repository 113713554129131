import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/Process.css";
import { ShippingContext } from "../Context/ShippingContext";
import { AuthContext } from "../Context/context";
import Spinner from "../Spinner/Spinner";
// import ProcessDropDown from "../Pages/ProcessDropDown";

const Processing = () => {
  const { itemsInfo, senderInfo, receiverInfo } =
    useContext(ShippingContext);

  const [isLoading, setIsLoading] = useState(false);
  const [dispatchInfo, setDispatchInfo] = useState(null);
  const [shipingCost, setShippingCost] = useState(null);
  const [resDataId, setResdataId] = useState(null)

  const formatCurrency = (amountInKobo) => {
    const amountInNaira = amountInKobo / 100; // Convert from kobo to naira
    return amountInNaira.toLocaleString("en-NG", {
      style: "currency",
      currency: "NGN",
      minimumFractionDigits: 2,
    });
  };
  

  const [error, setError] = useState(null);
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();

  const senderStateIdentifier = localStorage.getItem(
    "senders_state_identifier"
  );

  const receiverStateIdentifier = localStorage.getItem(
    "receivers_state_identifier"
  );

  const totalCost = localStorage.getItem("totalCost");
  console.log(totalCost);

  const storedCategoryInfo = JSON.parse(localStorage.getItem("categoryInfo"));
  const categoryIdentifier = storedCategoryInfo?.identifier || null;

  const estimatedDeliveryDate = localStorage.getItem("estimated_delivery_date");

  const shippingCost = localStorage.getItem("shipping_cost");
  console.log("s cost",shippingCost)

  const sendDataToBackend = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Category Identifier:", categoryIdentifier);

    const dataToSend = {
      name: storedCategoryInfo.name,
      insurance_type: itemsInfo.insurance_type,
      weight: parseFloat(itemsInfo.weight.replace(/[^0-9.-]+/g, "")),
      quantity: parseFloat(itemsInfo.quantity.replace(/[^0-9.-]+/g, "")),
      value: parseFloat(itemsInfo.value.replace(/[^0-9.-]+/g, "")),
      shipping_information: {
        estimated_delivery_date: estimatedDeliveryDate,
        senders_name: senderInfo.senders_name,
        senders_phone_number: senderInfo.senders_phone_number,
        senders_email: senderInfo.senders_email,
        senders_address_1: senderInfo.senders_address_1,
        senders_address_2: senderInfo.senders_address_2,
        senders_zip_code: senderInfo.senders_zip_code,
        receivers_name: receiverInfo.receivers_name,
        receivers_phone_number: receiverInfo.receivers_phone_number,
        receivers_email: receiverInfo.receivers_email,
        receivers_address_1: receiverInfo.receivers_address_1,
        receivers_address_2: receiverInfo.receivers_address_2,
        receivers_zip_code: receiverInfo.receivers_zip_code,
        senders_state: senderInfo.senders_state,
        receivers_state: receiverInfo.receivers_state,
        senders_state_identifier: senderStateIdentifier,
        receivers_state_identifier: receiverStateIdentifier,
      },
    };
    if (shippingCost !== null) {
      dataToSend.shipping_cost = Number(shippingCost * 100);
    } else {
      dataToSend.shipping_cost = Number(totalCost);
    }
  

    console.log("Sending data to backend:", dataToSend);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Create/Package/${categoryIdentifier}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(dataToSend),
        }
      );

      const resData = await response.json();
      console.log(resData);

      if (!response.ok) {
        throw new Error(resData.message);
      }

      setResdataId(resData.results);   

      setDispatchInfo(resData.results.dispatch);

      setShippingCost(resData.results.shipping_cost);

      console.log("res from server", resData.results);
      setIsLoading(false);
    } catch (error) {
      console.error("Error sending data to backend:", error);
      setError(error.message || "Something went wrong");
      setIsLoading(false);
    }
  };

  const handleSuccess = () => {
    setDispatchInfo(null);
    localStorage.removeItem("shipping_cost");
    localStorage.removeItem("totalCost");
    navigate("/CreatePackages");
  };

  const handleBack = () => {
    // Clear relevant items from local storage
    localStorage.removeItem("shipping_cost");
    localStorage.removeItem("totalCost");
  
    // Navigate back to the "ItemsInfo" page
    navigate("/ItemsInfo");
  };
  
  return (
    <Fragment>
      {dispatchInfo && (
        <div className="success">
          <h1>Dispatch Information</h1>
          <p>Status: {dispatchInfo.status}</p>
          <h2>Identifier: {resDataId.identifier}</h2>
          <h2>Delivery Cost: {formatCurrency(shipingCost)}</h2>
          <span>
            <button onClick={handleSuccess}>OKAY</button>
          </span>
        </div>
      )}
      <div className="process-container">
        <img
          src="https://i.ibb.co/hmKgjdq/nav-bar-nationwide-delivery-2x.png"
          alt=""
        />
        <h1>NATIONWIDE DELIVERY</h1>
        <p>Deliver to your doorstep Nationwide.</p>
        <form>
          <img src="https://i.ibb.co/wNZ5QSb/Frame-100-2x-1.png" alt="" />
          <p>
            Simply use details in the format below as your shipping address when
            shipping from any store (physical or online). In cases where you are
            required to input a phone number, use your personal number please.
            This will enable Doorstep Logistics to receive item(s) on your
            behalf for onward delivery.
          </p>
          <div className="delivery-item">
            <span>
              <p>Delivery Items</p>
            </span>
            <div className="items">
              <table>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Category</th>
                    <th>Weight</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={categoryIdentifier}>
                    <td>{itemsInfo.description}</td>
                    <td>{storedCategoryInfo.name}</td>
                    <td>{itemsInfo.weight}</td>
                    <td>{itemsInfo.quantity} piece(s)</td>
                  </tr>
                </tbody>
              </table>
              {/* <i
                className="fa-solid fa-ellipsis-vertical"
                onClick={() => setIsDrop(!IsDrop)}
              ></i>
              {IsDrop && <ProcessDropDown />} */}
            </div>
          </div>
      
          <div className="amount">
      {shippingCost !== null ? (
        <span>
          <p>Shipping Cost</p>
          <h1>
            {parseFloat(shippingCost ).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
              minimumFractionDigits: 2,
            })}
          </h1>
        </span>
      ) : (
        <span>
          <p>Delivery Cost</p>
          <h1>
            {parseFloat(totalCost / 100).toLocaleString("en-NG", {
              style: "currency",
              currency: "NGN",
              minimumFractionDigits: 2,
            })}
          </h1>
        </span>
      )}

      <span>
        <p>Cost of Items</p>
        <h1>
          {parseFloat(itemsInfo.value).toLocaleString("en-NG", {
            style: "currency",
            currency: "NGN",
            minimumFractionDigits: 2,
          })}
        </h1>
      </span>
    </div>
          {error && <p>{error}</p>}
          <div className="btn">
            <button onClick={handleBack}>Back</button>
            <button type="submit" onClick={sendDataToBackend}>
              {isLoading ? <Spinner /> : "Proceed"}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Processing;
