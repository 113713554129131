import { useContext } from "react";
import { AuthContext } from "../Context/context";
import ChangePasswordLogs from "./ChangePasswordLogs";

const LogsSet = () => {
  const { LogOut, handlePass, pass, user } = useContext(AuthContext);
  const username = user.email.split('@')[0];
  return (
    <div className="logs-setting">
      <div className="logs-info">
        <span>
          <p>FULL NAME</p>
          <p>{username}</p>
        </span>
        <span>
          <p>EMAIL</p>
          <p>{user.email}</p>
        </span>

        <span>
          <button onClick={() => LogOut()}>LOGOUT</button>
          <p onClick={() => handlePass()}>Change password</p>
        </span>
        {pass && (
          <div className="over-pass">
            <ChangePasswordLogs />
          </div>
        )}
      </div>
    </div>
  );
};

export default LogsSet;
