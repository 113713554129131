import React, { useState, useContext, Fragment } from "react";
import {
  useQuery,
  useMutation,
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { AuthContext } from "../Context/context";
import { useNavigate } from "react-router-dom";

const AssignmentUtilityDispatch = () => {
  const { token } = useContext(AuthContext);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [success, setSucces] = useState(false);
  const [isLaoding, setsLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorPop, setErrorPop] = useState(false)
  const queryClient = new QueryClient();
  const navigate = useNavigate()

  

  const fetchDrivers = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Drivers`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
  
    // Filter out inactive or suspended drivers
    const activeDrivers = data.results.filter(
      (driver) => driver.is_active && !driver.is_suspended
    );
  
    console.log("drivers", activeDrivers);
    return activeDrivers;
  };
  

 
  const fetchPackages = async (token) => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Packages`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
  
    // Filter out packages with status other than "in-warehouse"
    const inWarehousePackages = data.results.filter(
      (pkg) => pkg.dispatch.status.toLowerCase() === "in-warehouse"
    );
  
    console.log("packages", inWarehousePackages);
    return inWarehousePackages.reverse();
  };
  

  const assignPackagesToDriver = async () => {
    setsLoading(true)
    try {
      console.log("Request Payload:", {
        driver_identifier: selectedDriver.identifier,
        package_identifiers: selectedPackages,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Manager/Request/MultipleDispatch/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            driver_identifier: selectedDriver.identifier,
            package_identifiers: selectedPackages,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.results && data.results.package_identifiers
          ? data.results.package_identifiers[0]
          : data.message);        
      }

      setsLoading(false)
      setError(false)
      
      setSucces("Request Driver to Dispatch Multiple Packages successful Successful");

      return data;
    } catch (error) {
     setError(error.message)
     setsLoading(false)
     setErrorPop(true)
      throw error;
    }
  };

  const {
    data: drivers,
    isError: driversError,
    isLoading: driversLoading,
  } = useQuery("drivers", () => fetchDrivers(token));

  const {
    data: packages,
    isError: packagesError,
    isLoading: packagesLoading,
  } = useQuery("packages", () => fetchPackages(token));

  const mutation = useMutation(() => assignPackagesToDriver(), {
    onSuccess: () => {
      queryClient.invalidateQueries("drivers");
      queryClient.invalidateQueries("packages");
    },
  });

  if (driversLoading || packagesLoading) {
    return <div>Loading...</div>;
  }

  if (driversError || packagesError) {
    return <div>Error loading data</div>;
  }

  const filteredDrivers = drivers.filter((driver) =>
    driver.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredPackages = packages.filter((pkg) =>
    pkg.shipping_information.receiver.state.state_label
      .toLowerCase()
      .includes(selectedState.toLowerCase())
  );

  const isButtonDisabled = !selectedDriver || selectedPackages.length === 0;

  return (
    <QueryClientProvider client={queryClient}>
      <Fragment>
       {
        errorPop  && (
          <div className="success-multi">
            <div className="succ">
            {error &&  <p style={{color:"red"}}>{error}</p>}
            <button onClick={() => 
              {
                setErrorPop(false)
                setError(null)
              }} style={{backgroundColor:"red"}}>Okay</button>
            </div>
          </div>
        )
       }
        {success && (
          <div className="success-multi">
            <div className="succ">
              <h3>{success}</h3>
              <button onClick={() => 
              {
                setSucces(false)
                navigate("/ActiveDispatch")
              }}>Okay</button>
            </div>
          </div>
        )}
        <div className="assignment">
          <div className="add-trips">
            <p>Trips</p>
            <button
              onClick={() =>
                mutation.mutate({
                  driverId: selectedDriver,
                  packageIds: selectedPackages,
                })
              }
              disabled={isButtonDisabled}
              className={isButtonDisabled ? "disabled-button" : ""}
            >
              {isLaoding ? " Assign..." :" Assign Packages to Driver"}
             
            </button>
          </div>
          <div className="wrapper-assign">
            <div className="pakgs">
              <span>
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  type="search"
                  placeholder="Search by State...."
                  value={selectedState}
                  onChange={(e) => setSelectedState(e.target.value)}
                />
              </span>
              <div className="pakgs-card">
                {filteredPackages && Array.isArray(filteredPackages)
                  ? filteredPackages.map((pkg) => (
                      <li key={pkg.identifier}>
                        <span>
                          <h3>ID{pkg.identifier.slice(0, 12).replace(/-/g, '').toUpperCase()}</h3>
                          <p>{pkg.name}</p>
                          <p>
                            State :{" "}
                            {
                              pkg.shipping_information.receiver.state
                                .state_label
                            }
                          </p>
                        </span>

                        <button
                          onClick={() => {
                            const isSelected = selectedPackages.includes(
                              pkg.identifier
                            );
                            setSelectedPackages((prevSelected) => {
                              if (isSelected) {
                                return prevSelected.filter(
                                  (id) => id !== pkg.identifier
                                );
                              } else {
                                return [...prevSelected, pkg.identifier];
                              }
                            });
                          }}
                        >
                          {selectedPackages.includes(pkg.identifier)
                            ? "Remove"
                            : "Add"}
                        </button>
                      </li>
                    ))
                  : null}
              </div>
            </div>

            <div className="driver">
              <span>
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  type="search"
                  placeholder="Search by Name"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </span>
              <div className="logs-driver">
                {Array.isArray(filteredDrivers)
                  ? filteredDrivers.map((driver) => (
                      <li
                        key={driver.identifier}
                        onClick={() => setSelectedDriver(driver)}
                        className={`driver-item ${
                          driver.is_active && !driver.is_suspended
                            ? "active"
                            : "suspended"
                        }`}
                      >
                        <span>
                          <h3>ID{driver.identifier.slice(0, 12).replace(/-/g, '').toUpperCase()}</h3>
                          <p>{driver.full_name}</p>
                          <i
                            className={`fa-solid fa-circle ${
                              driver.is_active && !driver.is_suspended
                                ? "green"
                                : "red"
                            }`}
                          ></i>
                        </span>
                      </li>
                    ))
                  : null}
              </div>
            </div>
            {selectedDriver && (
              <div className="driveri-info-id">
                <h3>Driver Details</h3>
                <div className="card-dri">
                  <span>
                    <h3>Full Name</h3>
                    <p>{selectedDriver.full_name}</p>
                  </span>
                  <span>
                    <h3>Vehicle Type</h3>
                    <p>{selectedDriver.vehicle_type}</p>
                  </span>
                  <span>
                    <h3>Email</h3>
                    <p>{selectedDriver.email}</p>
                  </span>
                  <span>
                    <h3>Vehicle Plate Number</h3>
                    <p>{selectedDriver.vehicle_plate_number}</p>
                  </span>
                  <span>
                    <h3>Vehicle Reg Number</h3>
                    <p>{selectedDriver.vehicle_registration_number}</p>
                  </span>

                  <span>
                    <h3>Address</h3>
                    <p>{selectedDriver.address}</p>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </Fragment>
    </QueryClientProvider>
  );
};

export default AssignmentUtilityDispatch;
