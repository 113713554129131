import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../Context/context";


const DropDown = () => {

    const {setIsDropDown, setIsmobile} = useContext(AuthContext)

    const handleDropDown = () => {
        setIsDropDown(false)
        setIsmobile(false)
    }
    
    return ( 
        <div className="drop-down">
            <span onClick={handleDropDown}>
                <img src="https://i.ibb.co/hYGpt6w/Group.png" alt="" />
                <Link to='/' >Nationwide delivery</Link>
            </span>
            <span onClick={handleDropDown}>
                <img src="https://i.ibb.co/ygKhRhf/delivery-box-svgrepo-com-1.png" alt="" />
                <Link to='/'>Overseas Shipping</Link>
            </span>

            <span onClick={handleDropDown}>
                <img src="https://i.ibb.co/9sN8gsC/Vector.png" alt="" />
                <Link to='/'>Dispatch Service</Link>
            </span>

            <span onClick={handleDropDown}>
                <img src="https://i.ibb.co/RydqhgR/Vector-1.png" alt="" />
                <Link to='/'>Courier Services</Link>
            </span>

            <span onClick={handleDropDown}>
                <img src="https://i.ibb.co/ZKwVMN9/Group-1.png" alt="" />
                <Link to='/'>Charter Services</Link>
            </span>

            <span onClick={handleDropDown}> 
                <img src="https://i.ibb.co/S0C9dvN/Vector-3.png" alt="" />
                <Link to='/'>House-hold Movers</Link>
            </span>
        </div>
     );
}
 
export default DropDown;