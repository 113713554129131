import {NavLink , Outlet } from "react-router-dom";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../Css/super.css";

const NavLinkLCss = ({ isActive }) => {
  return {
    background: isActive ? "#1f4798" : "transparent",
  };
};


const SupperAdmin = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <div className="upper">
        <div className="islogedin">
          <i className="fa-regular fa-bell"></i>
          <span>
            <i className="fa-regular fa-user"></i>
            <p style={{fontFamily:"Montserrat"}}> Super admin</p>
          </span>
        </div>
      </div>
      <div className="super-admin">
        <img
          src="https://i.ibb.co/zRW2Jp5/Layer-1-2x.png"
          alt=""
          onClick={() => navigate("/OverView")}
        />
        <div className="sidebar-nav">
          <NavLink to="/OverView" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
            Overview
          </NavLink>
          <NavLink to="/Logistics" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
            Logistics Officer
          </NavLink>
          <NavLink to="/Driver" style={NavLinkLCss}>
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
            Drivers
          </NavLink>
          <NavLink to="/Profile" style={NavLinkLCss}> 
            <img src="https://i.ibb.co/pK38jgg/element-3-2x.png" alt="" />
            Settings
          </NavLink>
        </div>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default SupperAdmin;
