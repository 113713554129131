import { useContext, Fragment } from "react";
import { AuthContext } from "../Context/context";
import ResetPass from "./Reset-pass";
import "../Css/super.css";

const Profile = () => {
  const {
    editEmail,
    setEditEmail,
    editPhone,
    setEditPhone,
    LogOut,
    handlePass,
    pass,
    user
  } = useContext(AuthContext);

  // const handelEditmail = () => {
  //   setEditEmail(true);
  // };

  // const handleeditPhone = () => {
  //   setEditPhone(true);
  // };

  return (
    <Fragment>
      {editEmail && (
        <div className="update-email">
          <form>
            <span>
              <p>Email Addrees</p>
              <i
                className="fa-solid fa-xmark"
                onClick={() => setEditEmail(false)}
              ></i>
            </span>
            <input type="email" placeholder="Example@gmail.com" required />
            <div className="btn">
              <button onClick={() => setEditEmail(false)}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      )}
      {editPhone && (
        <div className="update-email">
          <form>
            <span>
              <p>Phone Number</p>
              <i
                className="fa-solid fa-xmark"
                onClick={() => setEditPhone(false)}
              ></i>
            </span>
            <input type="text" placeholder="080 000000000" required />
            <div className="btn">
              <button onClick={() => setEditPhone(false)}>Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      )}
      <div className="profile">
        <ul>
          <li>
            First Name <span>Super</span>
          </li>
          <li>
            Last Name <span>Admin</span>
          </li>
          <li>
            Email Address{" "}
            <span>
             {user.email}{" "}
              {/* <i className="fa-solid fa-pencil" onClick={handelEditmail}></i> */}
            </span>
          </li>
          <li>
            Phone Number{" "}
            <span>
            {user.phone_number || "N/A"} {" "}
              {/* <i className="fa-solid fa-pencil" onClick={handleeditPhone}></i>{" "} */}
            </span>
          </li>
          <li onClick={() => handlePass()} style={{cursor:"pointer"}}>Change Password</li>
          <button onClick={() => LogOut()}>Logout</button>
        </ul>
      </div>
        {pass && (
          <div className="over-pass-supper">
            <ResetPass />
          </div>
        )}
    </Fragment>
  );
};

export default Profile;
