import { createContext, useState } from "react";

export const StateContext = createContext({
  chatBoat: false,
  setChatboat: () => {},
  hide: false,
  setHide: () => {},
});

export const StateProvider = ({ children }) => {
  const [chatBoat, setChatboat] = useState(false);
  const [hide, setHide] = useState(false)
  const value = { chatBoat, setChatboat, hide, setHide };

  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};
