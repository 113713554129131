import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Fragment } from "react";
import "../Css/Services.css";

const Services = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Services | Doorstep</title>
        <meta name="description" content="Our services page of Doorstep, your trusted logistics partner." />
        <link rel="icon" href="/public/favicon.ico" />
        <meta property="og:type" content="website" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:url" content="https://www.doorstepng.com/" />

  
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta name="twitter:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta name="twitter:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="instapp:site_name" content="Doorstep" />
  <meta property="instapp:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="instapp:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="instapp:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />

  
  <meta property="tiktok:card" content="summary_large_image" />
  <meta property="tiktok:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="tiktok:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="tiktok:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:url" content="https://www.doorstepng.com/" />
      </Helmet>
    <div className="services-container">
      <div className="services-banner">
        <div className="box">
          <h1>NATIONWIDE DELIVERY</h1>
          <h4>Deliver to your doorstep Nationwide </h4>
          <p>
            We know how important it is to deliver your goods quickly and
            safely. That's why we offer nationwide delivery services that are
            second to none in Nigeria. We have a vast network of carriers and
            can deliver your goods to any location in the country, no matter how
            remote. We also offer tracking and tracing services so you can
            always know where your goods are. Let's pick up your shipments and
            deliver them to your doorstep Nationwide without stress
          </p>
          <div className="hire">
            <Link>Hire a Van</Link>
            <Link>Get Qoute</Link>
          </div>
        </div>
        <div className="box">
          <img src="https://i.ibb.co/TwChB32/Downloader-3.png" alt="" />
        </div>
      </div>
      <div className="oversea-container">
        <div className="box">
          <img src="https://i.ibb.co/M7tpHc5/Downloader-3.png" alt="" />
        </div>
        <div className="box">
          <h1>OVERSEASE SHIPPING</h1>
          <h4>Ship your goods overseas with peace of mind</h4>
          <p>
            Shipping goods overseas can be daunting, but it doesn't have to be.
            With Us, you can ship your products from anywhere in the world to
            your doorstep in Nigeria. You can also quickly and stress-free
            export items from Nigeria to over 230 locations worldwide, including
            the UK, USA, Europe, South Africa, Ghana, China, Germany, and
            Canada. Our team of experienced professionals can help you choose
            the suitable shipping method for your needs and ensure that your
            goods arrive safely and on time.
          </p>
          <div className="hire2">
            <Link>Hire a Van</Link>
            <Link>Get Qoute</Link>
          </div>
        </div>
      </div>
      <div className="charter-container">
        <div className="box">
          <h1>CHARTER SERVICES</h1>
          <h4>
            Book a Van or Sienna for your next event or transportation need
          </h4>
          <p>
            Whether planning a corporate event or a private party, we can
            provide the perfect charter service. We have Sienna's and vans of
            different sizes, ranging from 1.5 - 30 tons, available for hire.
          </p>
          <div className="hire3">
            <Link>Hire a Van</Link>
            <Link>Hire Sienna</Link>
          </div>
        </div>
        <div className="box">
          <img src="https://i.ibb.co/jw05ctt/Downloader-3-1.png" alt="" />
        </div>
      </div>
      <div className="house-mover">
        <div className="box">
          <img src="https://i.ibb.co/xS8ZzcT/Downloader-3-2.png" alt="" />
        </div>
        <div className="box">
          <h1>HOUSE HOLD MOVERS</h1>
          <h4>
            Let us take care of your move so you can relax and enjoy the process
          </h4>
          <p>
            Moving to a new house or office space? Moving can be a stressful
            experience, but it doesn't have to be. We offer comprehensive
            household moving services, from packing and loading to unpacking and
            delivery. Our team of experienced professionals will handle your
            move with care and professionalism.
          </p>
          <div className="hire4">
            <Link>Book Now</Link>
            <Link>Get Qoute</Link>
          </div>
        </div>
      </div>
      <div className="courier-services">
        <div className="box">
          <h1>COURIER SERVICES</h1>
          <h4>Fast, reliable, and affordable courier services.</h4>
          <p>
            With our courier service, your experience is personalized. We pick
            up parcels and deliver them to your doorstep at your convenience,
            whether you need a document delivered overnight or shipped across
            the country. Our team of experienced professionals will get your
            shipment where it needs to go quickly and safely.
          </p>
          <div className="hire5">
            <Link>Ship Now</Link>
            <Link>Get Qoute</Link>
          </div>
        </div>
        <div className="box">
          <img src="https://i.ibb.co/svSXXn9/Downloader-3-3.png" alt="" />
        </div>
      </div>
      <div className="dispatch-services">
        <div className="box">
          <img src="https://i.ibb.co/fD1DsdK/Downloader-3-4.png" alt="" />
        </div>
        <div className="box">
          <h1>DISPATCH SERVICES</h1>
          <h4>
            Let us dispatch your worries so you can focus on your business
          </h4>
          <p>
            We offer comprehensive dispatch services to help you efficiently
            manage your deliveries. Based on your company's need, we can
            dedicate a bike or a fleet of bikes to serve you. Our professionals
            ensure your deliveries are coordinated seamlessly, optimizing routes
            and schedules to maximize effectiveness and minimize potential
            disruptions.
          </p>
          <div className="hire6">
            <Link>Ship Now</Link>
            <Link>Get Qoute</Link>
          </div>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default Services;
