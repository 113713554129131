const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
  
    const optionsDate = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    const formattedDate = date.toLocaleDateString(undefined, optionsDate);
  
    const optionsTime = {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    };
    const formattedTime = date.toLocaleTimeString(undefined, optionsTime);
  
    return (
      <div className="date_time">
        <p>{formattedTime}</p>
        <p>{formattedDate}</p>
      </div>
    );
  };

  export default formatDateTime;