import React, { useEffect, useState, useContext, useCallback , Fragment} from "react";
import { Helmet } from "react-helmet";
import { AuthContext } from "../Context/context";
import { ShippingContext } from "../Context/ShippingContext";
import Spinner from "../Spinner/Spinner";
import "../Css/Qoutes.css";

const GetQoutes = () => {
  const { token } = useContext(AuthContext);
  const [states, setStates] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const [receiverStateIdentifier, setReceiverStateIdentifier] = useState(null);

  const {
    senderInfo,
    setSenderInfo,
    receiverInfo,
    setReceiverInfo,
    error,
    setError,
    setSendersStateIdentifier,
    setReceiversStateIdentifier,
    itemsInfo,
    setItemsInfo,
    handleItemInfoChange,
  } = useContext(ShippingContext);

  const fetchStateIdentifier = useCallback(
    async (state, infoSetter, isSender) => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/States`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const resData = await res.json();

        if (!res.ok || !resData.results) {
          throw new Error("Failed to fetch state data");
        }

        const stateData = resData.results.find((s) => s.state_label === state);

        if (!stateData) {
          throw new Error("State Data not found");
        }

        const stateIdentifier = stateData.identifier;

        const storageKey = isSender
          ? "senders_state_identifier"
          : "receivers_state_identifier";

        if (typeof localStorage !== "undefined") {
          localStorage.setItem(storageKey, stateIdentifier);

          

          infoSetter({
            ...infoSetter,
            [`${infoSetter.name}_state_identifier`]: stateIdentifier,
          });

          if (!isSender) {
            setReceiverStateIdentifier(stateIdentifier);
          }
        }

      } catch (error) {
        setError(error.message);
        console.error(error.message);
      }
    },
    [token, setError]
  );

  useEffect(() => {
    const sendHttpReq = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/States`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (!res.ok) {
          throw new Error(resData.message);
        }
        setStates(resData);
        setError(false);
      } catch (error) {
        setError(error.message);
        console.log(error.message);
      }
    };
    sendHttpReq();
  }, [token, setError]);

  useEffect(() => {
    if (senderInfo.senders_state) {
      fetchStateIdentifier(
        senderInfo.senders_state,
        setSendersStateIdentifier,
        true // This is for the sender
      );
    }

    if (receiverInfo.receivers_state) {
      fetchStateIdentifier(
        receiverInfo.receivers_state,
        setReceiversStateIdentifier,
        false // This is for the receiver
      );
    }
  }, [
    senderInfo.senders_state,
    receiverInfo.receivers_state,
    token,
    setSenderInfo,
    setReceiverInfo,
    setSendersStateIdentifier,
    setReceiversStateIdentifier,
    setError,
    fetchStateIdentifier,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsloading(true);
    const valueInKobo = Number(itemsInfo.value) * 100;
    const weightAsNumber = Number(itemsInfo.weight);
    console.log(token);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Calculate/ShippingCost`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            insurance_type: itemsInfo.insurance_type,
            weight: weightAsNumber,
            value: valueInKobo,
            receivers_state_identifier: receiverStateIdentifier,
          }),
        }
      );

      const responseData = await res.json();
      console.log("cal ship", responseData);

      if (!res.ok) {
        throw new Error(
          `Failed to calculate shipping cost: ${res.status} - ${res.statusText}`
        );
      }
      setIsloading(false);
    } catch (error) {
      setIsloading(false);
      setError(error.message);
      console.error("Error during shipping cost calculation:", error.message);
    }
  };

  return (
    <Fragment>
      <Helmet>
        <title>Get Qoutes| Doorstep</title>
        <meta name="description" content="Get Qoutes page of Doorstep, your trusted logistics partner." />
        <link rel="icon" href="/public/favicon.ico" />
        <meta property="og:type" content="website" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:url" content="https://www.doorstepng.com/" />

  
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta name="twitter:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta name="twitter:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="instapp:site_name" content="Doorstep" />
  <meta property="instapp:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="instapp:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="instapp:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />

  
  <meta property="tiktok:card" content="summary_large_image" />
  <meta property="tiktok:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="tiktok:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="tiktok:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:url" content="https://www.doorstepng.com/" />
      </Helmet>
    <div className="qoutes-container">
      <div className="header">
        <h1>GET A FREE DOMESTIC OR INTERNATIONAL SHIPPING QUOTE ONLINE</h1>
        <p>
          Packages and pallets, big and small, we can offer you an instant quote
          for your shipping needs both domestically and internationally. Fill
          out your shipment details below to discover your quotes. If you are
          satisfied, simply continue to book.
        </p>
        <div className="box-header">
          <div className="box">
            <h3>1</h3>
            <h4>ENTER ORIGIN AND DESTINATION</h4>
          </div>
          <div className="box">
            <h3>2</h3>
            <h4>COMPLETE YOUR SHIPMENT DETAILS</h4>
          </div>
          <div className="box">
            <h3>3</h3>
            <h4>GET AN ESTIMATED QUOTE</h4>
          </div>
        </div>
      </div>
      <div className="disqoute-container">
        <h3>Dispatch Services</h3>
        <div className="qoute-form">
          <form onSubmit={handleSubmit}>
            <div className="all-inputs">
              <span>
                <label>
                  Select Departure State
                  <select
                    name="senderState"
                    value={senderInfo.senders_state}
                    onChange={(e) =>
                      setSenderInfo({
                        ...senderInfo,
                        senders_state: e.target.value,
                      })
                    }
                  >
                    {states &&
                      states.results &&
                      states.results.map((state) => (
                        <option
                          key={state.identifier}
                          value={state.state_label}
                        >
                          {state.state_label}
                        </option>
                      ))}
                  </select>
                </label>

                <label>
                  Select Destination State
                  <select
                    name="receiverState"
                    value={receiverInfo.receivers_state}
                    onChange={(e) =>
                      setReceiverInfo({
                        ...receiverInfo,
                        receivers_state: e.target.value,
                      })
                    }
                  >
                    {states &&
                      states.results &&
                      states.results.map((state) => (
                        <option
                          key={state.identifier}
                          value={state.state_label}
                        >
                          {state.state_label}
                        </option>
                      ))}
                  </select>
                </label>
              </span>

              <span>
                <label>
                  Quantity
                  <input
                    type="number"
                    name="quantity"
                    value={itemsInfo.quantity}
                    onChange={handleItemInfoChange}
                  />
                </label>

                <label>
                  Select weight range (KG)
                  <input
                    type="number"
                    name="weight"
                    value={itemsInfo.weight}
                    onChange={handleItemInfoChange}
                  />
                </label>
              </span>

              <span>
                <label>
                  Value (Naira)
                  <input
                    type="number"
                    name="value"
                    value={itemsInfo.value}
                    onChange={handleItemInfoChange}
                  />
                </label>

                <label>
                  Insurance Type
                  <div>
                    <input
                      type="radio"
                      id="premium"
                      name="insurance_type"
                      value="premium-insurance"
                      checked={itemsInfo.insurance_type === "premium-insurance"}
                      onChange={() =>
                        setItemsInfo({
                          ...itemsInfo,
                          insurance_type: "premium-insurance",
                        })
                      }
                    />
                    <label htmlFor="premium">Premium Insurance</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="basic"
                      name="insurance_type"
                      value="basic-insurance"
                      checked={itemsInfo.insurance_type === "basic-insurance"}
                      onChange={() =>
                        setItemsInfo({
                          ...itemsInfo,
                          insurance_type: "basic-insurance",
                        })
                      }
                    />
                    <label htmlFor="basic">Basic Insurance</label>
                  </div>
                </label>
              </span>
            </div>
            <button disabled>
              {isLoading ? <Spinner /> : "Get Quote"}
            </button>
            {/* <button disabled={isLoading}>
              {isLoading ? <Spinner /> : "Get Quote"}
            </button> */}
            {error && <p>{error}</p>}
          </form>
        </div>
      </div>
    </div>
    </Fragment>
  );
};

export default GetQoutes;

