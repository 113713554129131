import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/context";
import Spinner from "../Spinner/Spinner";
import "../Css/super.css";

const defaultForm = {
  startDate: "",
  endDate: "",
};

function FullInfoDriver() {
  const { token } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const [driverData, setDriverData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [suspend, setSuspend] = useState(false);
  const [formField, setFormField] = useState(defaultForm);
  const [successMessage, setSuccessMessage] = useState(null);
  const [successMessageDeactivate, setSuccessMessageDeactivate] = useState(null);
  const [suspensions, setSuspensions] = useState([]);
  const [endSuspendCon, setEndSuspenCon] = useState(false);
  const [deactivateConfirmation, setDeactivateConfirmation] = useState(false);

  const { startDate, endDate } = formField;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormField({ ...formField, [name]: value });
  };

  useEffect(() => {
    const fetchSuspensions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Suspensions/Staff`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message);
        }

        setSuspensions(data.results.suspensions);
        console.log("Fetch Suspensions Successful", data);
      } catch (error) {
        console.error("Error fetching suspensions:", error);
      }
    };

    fetchSuspensions();
  }, [token]);

  const isDriverSuspended = (driverId) => {
    return (
      suspensions &&
      suspensions.length > 0 &&
      suspensions.some(
        (suspension) =>
          suspension.driver && suspension.driver.identifier === driverId && suspension.is_active
      )
    );
  };

  useEffect(() => {
    const fetchDriverInfo = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Drivers/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        console.log(data);

        if (response.status !== 200) {
          throw new Error(data.message || "Failed to fetch driver information");
        }

        setDriverData(data.results);
      } catch (error) {
        console.error("Error fetching driver information:", error);
        setError(error.message || "An error occurred while fetching data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchDriverInfo();
  }, [id, token]);

  const confirmEndSuspension = () => {
    setEndSuspenCon(true);
  };

  const confirmDeactivate = () => {
    handleDeactivate()
    setDeactivateConfirmation(true);
  };

  const cancelEndSuspension = () => {
    setEndSuspenCon(false);
  };

  const cancelDeactivate = () => {
    setDeactivateConfirmation(false);
  };

  const handleSuspend = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Suspend/Driver`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            driver_identifier: id,
            start_date: startDate,
            end_date: endDate,
          }),
        }
      );

      const data = await response.json();

      if (response.status !== 200) {
        console.error("Error suspending driver:", data);
        throw new Error(data.message || "Failed to suspend driver");
      }

      setSuccessMessage(data.message);
      setTimeout(() => {
        setSuccessMessage("");
        navigate('/Driver');
      }, 2000);
      console.log(data.message);

      setFormField(defaultForm);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error handling suspension:", error);
      setError(
        error.message || "An error occurred while suspending the driver"
      );
    }
  };

  const handleEndSuspension = async () => {
    setIsLoading(true);

    try {
      if (!isDriverSuspended(id)) {
        console.error("Driver is not currently suspended");
        setIsLoading(false);
        return;
      }

      const suspension = suspensions.find(
        (suspension) =>
          suspension.driver && suspension.driver.identifier === id && suspension.is_active
      );

      if (!suspension) {
        console.error("Suspension not found");
        setIsLoading(false);
        return;
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Process/End/Suspension`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            identifier: suspension.identifier,
          }),
        }
      );

      const data = await response.json();

      console.log("Response:", data);

      if (response.ok) {
        console.log("Suspension ended successfully");
        setSuspensions((prevSuspensions) =>
          prevSuspensions.map((prevSuspension) =>
            prevSuspension.identifier === suspension.identifier
              ? { ...prevSuspension, is_active: false }
              : prevSuspension
          )
        );
        navigate('/Driver');
      } else {
        if (response.status === 404) {
          console.error("Staff Suspension Not Found");
        } else {
          throw new Error(data.message);
        }
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeactivate = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Deactivate/Driver`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            driver_identifier: id,
          }),
        }
      );

      const data = await response.json();
      console.log(data);

      if (response.status !== 200) {
        console.error("Error deactivating driver:", data);
        throw new Error(data.message || "Failed to deactivate driver");
      }
      setSuccessMessageDeactivate(data.message);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error handling deactivation:", error);
      setError(
        error.message || "An error occurred while deactivating the driver"
      );
    }
  };

  return (
    <Fragment>
      {suspend && (
        <div className="suspend-container">
          <h3>Are you sure you want to suspend this driver?</h3>
          <div className="suspend-form">
            <label>
              Start Date
              <input
                type="date"
                name="startDate"
                value={startDate}
                onChange={handleChange}
              />
            </label>
            <label>
              End Date
              <input
                type="date"
                name="endDate"
                value={endDate}
                onChange={handleChange}
              />
            </label>
            {error && <p>{error}</p>}
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            <div className="sus-btn">
              <button
                onClick={() => {
                  setSuspend(false);
                  setError(null);
                }}
              >
                Cancel
              </button>
              <button onClick={handleSuspend} disabled={isLoading}>
                {isLoading ? <Spinner /> : "Suspend Driver"}
              </button>
            </div>
          </div>
        </div>
      )}
      {endSuspendCon && (
        <div className="end-suspend-container">
          <h3>Are you sure you want to End suspension for this driver?</h3>
          <div className="endsuspend-form">
            <div className="endsus-btn">
              <button onClick={cancelEndSuspension}>Cancel</button>
              <button onClick={handleEndSuspension}>
                {isLoading ? <Spinner /> : " End Suspension"}
              </button>
            </div>
          </div>
        </div>
      )}
      {deactivateConfirmation && (
        <div className="deactivate-overlay">
          <h3>Are you sure you want to deactivate this driver?</h3>
          <p>Please Note this action is irreversible.</p>
          {successMessageDeactivate && (
                    <p className="success-message" style={{color:"green"}}>
                      {successMessageDeactivate}
                    </p>
                  )}
             <div className="deactivate-form">
          <div className="deactivate-btn">
            <button onClick={cancelDeactivate}>Cancel</button>
            <button onClick={confirmDeactivate}>Confirm</button>
              </div>     
          </div>
        </div>
      )}
      <div className="full-info-driver">
        <div className="add-driver">
          <p>Driver</p>
          <button onClick={() => navigate("/AddDrivers")}>Add Driver</button>
        </div>

        <div className="wrapper">
          {driverData ? (
            <>
              <div className="header-info">
                <span>
                  <i
                    className="fa-solid fa-arrow-left"
                    onClick={() => navigate("/Driver")}
                  ></i>
                  <p>{driverData.identifier}</p>
                </span>

                <span>
                  {driverData.is_suspended ? (
                    <button onClick={confirmEndSuspension}>
                      {isLoading ? <Spinner /> : " End Suspension"}
                    </button>
                  ) : (
                    <button onClick={() => setSuspend(true)}>Suspend</button>
                  )}
                </span>
              </div>
              <div className="driver-infoid">
                <span>
                  <h2>First Name</h2>
                  <p>{driverData.first_name}</p>
                </span>
                <span>
                  <h2>Last Name</h2>
                  <p>{driverData.last_name}</p>
                </span>
                <span>
                  <h2>Email</h2>
                  <p>{driverData.email}</p>
                </span>

                <span>
                  <h2>Address</h2>
                  <p>{driverData.address}</p>
                </span>

                <span>
                  <h2>Vehicle Type</h2>
                  <p>{driverData.vehicle_type}</p>
                </span>

                <span>
                  <h2>Plate Number</h2>
                  <p>{driverData.vehicle_plate_number}</p>
                </span>

                <span>
                  <h2>Registered Vehicle</h2>
                  <p>{driverData.vehicle_registration_number}</p>
                </span>

                <span>
                  <h2>Driver's License Number</h2>
                  <p>{driverData.drivers_license_number}</p>
                </span>

                <span>
                  <button onClick={() => setDeactivateConfirmation(true)} disabled={isLoading}>
                    {isLoading ? <Spinner /> : "Deactivate Driver"}
                  </button>
                 
                </span>
              </div>
            </>
          ) : (
            <p>{error}</p>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default FullInfoDriver;

