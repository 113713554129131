import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ImageUpload from "../utils/image-picker";
import { AuthContext } from "../Context/context.js";
import { ShippingContext } from "../Context/ShippingContext";
import Spinner from "../Spinner/Spinner.jsx";
import "../Css/Items.css";

const ItemsInfo = () => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState([]);
  const [categoryIdentifier, setCategoryIdentifier] = useState(null);
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState("");
  const [shipCost, setShipcost] = useState("")
  const [isLoading, setIsloading] = useState(false)
  const handleEstimatedDeliveryDateChange = (e) => {
    setEstimatedDeliveryDate(e.target.value);
  };

  const handlShipCost = (e) => {
    setShipcost(e.target.value)
  }

  

  const {
    itemsInfo,
    setItemsInfo,
    saveItemsInfo,
    handleImage,
    handleItemInfoChange,
    receiverInfo,
  } = useContext(ShippingContext);

  const receiverStateIdentifier = localStorage.getItem(
    "receivers_state_identifier"
  );

  useEffect(() => {
    const sendHttpReq = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/PackageCategories`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const resData = await res.json();
        if (!res.ok) {
          throw new Error(resData.message);
        }
        console.log(resData);
        setCategory(resData.results);
      } catch (error) {
        setError(error.message);
      }
    };
    sendHttpReq();
  }, [token]);

 



  const handleSubmit = async () => {
    setIsloading(true);
  
    try {
      // Save items info to context
      saveItemsInfo();
  
      // Save itemsInfo to localStorage
      localStorage.setItem("itemsInfo", JSON.stringify(itemsInfo));
  
      // Create categoryInfo object
      const categoryInfo = {
        identifier: categoryIdentifier,
        name: category.find((item) => item.identifier === categoryIdentifier)?.name,
      };
  
      // Save categoryInfo to localStorage
      localStorage.setItem("categoryInfo", JSON.stringify(categoryInfo));
  
      // Save estimated delivery date to localStorage
      localStorage.setItem("estimated_delivery_date", estimatedDeliveryDate);
  
      // Check if shipping cost is provided
      if (shipCost !== "") {
        // Save shipping cost to localStorage
        localStorage.setItem("shipping_cost", shipCost);
  
        // Proceed to the next page
        navigate("/Processing");
      } else {
        // Calculate shipping cost
        const valueInKobo = Number(itemsInfo.value) * 100;
        const weightAsNumber = Number(itemsInfo.weight);
  
        const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Calculate/ShippingCost`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            insurance_type: itemsInfo.insurance_type, 
            weight: weightAsNumber,
            value: valueInKobo,
            receivers_state_identifier: receiverStateIdentifier,
          }),
        });
  
        const responseData = await res.json();
        console.log("cal ship", responseData);
  
        if (!res.ok) {
          throw new Error(`Failed to calculate shipping cost: ${res.status} - ${res.statusText}`);
        }
  
        // Save total cost to localStorage
        localStorage.setItem("totalCost", JSON.stringify(responseData.results.total_cost));
  
        // Proceed to the next page
        navigate("/Processing");
      }
    } catch (error) {
      setIsloading(false);
      setError(error.message);
      console.error("Error in handleSubmit:", error);
    }
  };
  
  
  
  

  const handleCategoryChange = (value) => {
    console.log("Identifier:", value.identifier, "Name:", value.name);
    setCategoryIdentifier(value.identifier);
  };

  return (
    <div className="items-info">
      <img
        src="https://i.ibb.co/hmKgjdq/nav-bar-nationwide-delivery-2x.png"
        alt=""
      />
      <h1>NATIONWIDE DELIVERY</h1>
      <p>Deliver to your doorstep Nationwide.</p>
      <form>
        <img src="https://i.ibb.co/vZnZKzt/Frame-100-2x.png" alt="" />
        <p>
          Simply use details in the format below as your shipping address when
          shipping from any store (physical or online). In cases where you are
          required to input a phone number, use your personal number please.
          This will enable Doorstep Logistics to receive item(s) on your behalf
          for onward delivery.
        </p>
        <p>Items' info</p>
        <span>
          <label>
            Categories
            <select
              name="description"
              id=""
              value={categoryIdentifier}
              onChange={(e) =>
                handleCategoryChange(
                  category.find((item) => item.identifier === e.target.value)
                )
              }
            >
              <option value="">select category</option>
              {category.map((categoryItem) => (
                <option
                  key={categoryItem.identifier}
                  value={categoryItem.identifier}
                >
                  {categoryItem.name}
                </option>
              ))}
            </select>
          </label>

          <label>
            Description
            <input
              type="text"
              name="description"
              value={itemsInfo.decription}
              onChange={handleItemInfoChange}
            />
          </label>

          <label>
            Select weight range (KG)
            <input
              type="text"
              name="weight"
              value={itemsInfo.weight}
              onChange={handleItemInfoChange}
            />
          </label>

          <label>
            Quantity
            <input
              type="text"
              name="quantity"
              value={itemsInfo.quantity}
              onChange={handleItemInfoChange}
            />
          </label>

          <label>
            Destination
            <input
              type="text"
              value={receiverInfo.receivers_address_1}
              readOnly
            />
          </label>

          <label>
            Value (Naira)
            <input
              type="number"
              name="value"
              value={itemsInfo.value}
              onChange={handleItemInfoChange}
            />
          </label>

          <label>
            Estimated Delivery Date
            <input
              type="date"
              value={estimatedDeliveryDate}
              onChange={handleEstimatedDeliveryDateChange}
            />
          </label>

          <label>
            Shipping Cost (Naira)
            <input
              type="number"
              value={shipCost}
              onChange={handlShipCost}
              placeholder="Optional"
            />
          </label>

          <label>
            Insurance Type
            <div>
              <input
                type="radio"
                id="premium"
                name="insurance_type"
                value="premium-insurance"
                checked={itemsInfo.insurance_type === "premium-insurance"}
                onChange={() =>
                  setItemsInfo({
                    ...itemsInfo,
                    insurance_type: "premium-insurance",
                  })
                }
              />
              <label htmlFor="premium">Premium Insurance</label>
            </div>
            <div>
              <input
                type="radio"
                id="basic"
                name="insurance_type"
                value="basic-insurance"
                checked={itemsInfo.insurance_type === "basic-insurance"}
                onChange={() =>
                  setItemsInfo({
                    ...itemsInfo,
                    insurance_type: "basic-insurance",
                  })
                }
              />
              <label htmlFor="basic">Basic Insurance</label>
            </div>
          </label>
        </span>
        <div className="image">
          <label>
            Upload item
            <ImageUpload onChange={handleImage} />
          </label>
        </div>

        {error && <p style={{ color: "red" }}>{error}</p>}
        <div className="btn">
          <button onClick={() => navigate("/ShippingAddress")}>Back</button>
          <button onClick={() => handleSubmit()}>{isLoading ? <Spinner/> : "Submit"}</button>
        </div>
      </form>
    </div>
  );
};

export default ItemsInfo;
