import React, { useRef, useState, useEffect } from "react";

const ImageUpload = ({ onChange }) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");

  const selectFile = useRef();

  useEffect(() => {
    if (!file) {
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewUrl(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, [file]);

  const selectedFile = (event) => {
    let pickedFile;

    if (event.target.files && event.target.files.length === 1) {
      pickedFile = event.target.files[0];
      setFile(pickedFile);
      onChange(pickedFile);
    }
  };

  const selectImageHandler = () => {
    selectFile.current.click();
  };

  return (
    <div className="file-form-container">
      <input
        type="file"
        id="image"
        style={{ display: "none" }}
        accept=".jpg,.png,.jpeg"
        ref={selectFile}
        onChange={selectedFile}
        required
      />
      <div className="image-upload">
        <div className="image-preview">
          {previewUrl && <img src={previewUrl} alt="preview" className="img" />}
          {!previewUrl && (
            <img
              src="https://i.ibb.co/X8Jrvc0/bx-cloud-upload.jpg"
              alt="preview"
            />
          )}
        </div>
        {file ? (
          ""
        ) : (
          <>
            <p>Upload an image</p>
            <button onClick={selectImageHandler}>Choose File</button>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageUpload;
