import { useState } from 'react';
import '../Css/Pages.css'



const AllFaqs = () => {

    const [answersVisible, setAnswersVisible] = useState({
        service: false,
        deliveryTime: false,
        tracking: false,
        damageOrLost: false,
        packing: false,
        rates:false,
        storage:false,
        shipment: false,
        about:false,
        wide: false,
        moving:false,
        quesstion:false,
        sea: false,
        courier: false,
        charter: false,
        warehousing: false,
        contact: false,
        what: false,
        parcel: false,
        account: false,
        fee: false,
        payments: false,
        have: false,
        arrival: false,
        items: false,
        naija: false,
        export: false,
        age: false,
      });
    
      
      const toggleAnswer = (key) => {
        setAnswersVisible((prev) => ({
          ...prev,
          [key]: !prev[key],
        }));
      };
    return ( 
        <div className="all-faqs">
            <div className="faqs-banner">
                <div className="overley">
                <h1>FREQUENTLY ASK QUESTIONS AND ANSWERS</h1>
                <p>What is on your mind about us</p>
                </div>
            </div>
            <div className="faqs">
      
      <h1>DOORSTEP LOGISTICS FAQ</h1>
      <p></p>
      <div className="faqs-card">
        <div className="faqs-ul">
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.service ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("service")}
              ></i>{" "}
              <p>What services do you offer?</p>
            </span>
            {answersVisible.service && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.rates ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("rates")}
              ></i>{" "}
              <p>What are your rates?</p>
            </span>
            {answersVisible.rates && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.deliveryTime ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("deliveryTime")}
              ></i>{" "}
              <p>How long does it take to deliver my goods?</p>
            </span>
            {answersVisible.deliveryTime && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.tracking ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("tracking")}
              ></i>{" "}
              <p>Do you offer tracking and tracing?</p>
            </span>
            {answersVisible.tracking && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.damageOrLost ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("damageOrLost")}
              ></i>{" "}
              <p>What happens if my shipment is damaged or lost?</p>
            </span>
            {answersVisible.damageOrLost && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.packing ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("packing")}
              ></i>{" "}
              <p>Can you help me pack my goods for shipping?</p>
            </span>
            {answersVisible.packing && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.storage ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("storage")}
              ></i>{" "}
              <p>Do you offer storage services?</p>
            </span>
            {answersVisible.storage && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.shipment ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("shipment")}
              ></i>{" "}
              <p>How do I package my shipment for international delivery?</p>
            </span>
            {answersVisible.shipment && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.about ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("about")}
              ></i>{" "}
              <p>What should I do if I have a question about my shipment?</p>
            </span>
            {answersVisible.about && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.wide ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("wide")}
              ></i>{" "}
              <p>What is the difference between nationwide delivery and overseas shipping?</p>
            </span>
            {answersVisible.wide && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.moving ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("moving")}
              ></i>{" "}
              <p>What is the difference between courier services and household moving?</p>
            </span>
            {answersVisible.moving && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.question ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("question")}
              ></i>{" "}
              <p>What should I do if I have a question about my shipment?</p>
            </span>
            {answersVisible.question && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.sea ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("sea")}
              ></i>{" "}
              <p>What is the difference between nationwide delivery and overseas shipping?</p>
            </span>
            {answersVisible.sea && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.courier ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("courier")}
              ></i>{" "}
              <p>What is the difference between courier services and household moving?</p>
            </span>
            {answersVisible.courier && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.charter ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("charter")}
              ></i>{" "}
              <p>What is the difference between charter services and dispatch services?</p>
            </span>
            {answersVisible.charter && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.warehousing ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("warehousing")}
              ></i>{" "}
              <p>What is the difference between warehousing and fulfillment?</p>
            </span>
            {answersVisible.warehousing && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
          <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.contact ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("contact")}
              ></i>{" "}
              <p>How can I contact you?</p>
            </span>
            {answersVisible.contact && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
        </div>
      </div>
    </div>
    <div className="overseas-faqs">
        <h3>Doorstep Logistics Overseas shipping</h3>
        <div className="faqs-card">
            <div className="faqs-ul">
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.what ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("what")}
              ></i>{" "}
              <p>What is Overseas shipping?</p>
            </span>
            {answersVisible.what && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.payments ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("payments")}
              ></i>{" "}
              <p>How do I make payment?</p>
            </span>
            {answersVisible.payments && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.parcel ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("parcel")}
              ></i>{" "}
              <p>Will my parcel be delivered to my final destination anywhere in Nigeria?</p>
            </span>
            {answersVisible.parcel && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.account ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("account")}
              ></i>{" "}
              <p>Does Doorstep Logistics require me to pay into any personal account?</p>
            </span>
            {answersVisible.account && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.fee ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("fee")}
              ></i>{" "}
              <p>How much is the shipping fee?</p>
            </span>
            {answersVisible.fee && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.arrival ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("arrival")}
              ></i>{" "}
              <p>What is the expected time of arrival (ETA) for international shipments?</p>
            </span>
            {answersVisible.arrival && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.items ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("items")}
              ></i>{" "}
              <p>Do you ship all kind of items?</p>
            </span>
            {answersVisible.items && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.naija ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("naija")}
              ></i>{" "}
              <p>Which countries do you ship to Nigeria?</p>
            </span>
            {answersVisible.naija && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.export ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("export")}
              ></i>{" "}
              <p>What is your export rate?</p>
            </span>
            {answersVisible.export && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.age ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("age")}
              ></i>{" "}
              <p>How do I package my shipment for international delivery?</p>
            </span>
            {answersVisible.age && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            <li>
            <span>
              <i
                className={`fa-solid ${
                  answersVisible.have ? "fa-minus" : "fa-plus"
                }`}
                onClick={() => toggleAnswer("have")}
              ></i>{" "}
              <p>What should I do if I have a question about my shipment?</p>
            </span>
            {answersVisible.have && (
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            )}
          </li>
            </div>
        </div>
    </div>
        </div>
     );
}
 
export default AllFaqs;