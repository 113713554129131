import React, { useState, useContext, Fragment } from "react";
import { AuthContext } from "../Context/context";
import Spinner from "../Spinner/Spinner";
import { useQuery } from "react-query";
import "../Css/Driveradmin.css";
import UpdateDispatch from "./UpdateDispatch";

const DriverHome = () => {
  const { token } = useContext(AuthContext);
  const [loadingStates, setLoadingStates] = useState({});
  const [error, setError] = useState(null);
  const [searchId, setSearchId] = useState("");
  const [acceptRequestIdentifier, setAcceptRequestIdentifier] = useState(null);
  const [rejectRequestIdentifier, setRejectRequestIdentifier] = useState(null);

  const fetchDispatchRequests = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Driver/Fetch/DispatchRequests`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }
    const reversedDispatches = data.results.reverse();
    return reversedDispatches;
  };

  const { data: fetchedDispatches, error: fetchError, refetch } = useQuery(
    "dispatchRequests",
    fetchDispatchRequests
  );

  const handleAccept = async (requestIdentifier) => {
    setAcceptRequestIdentifier(requestIdentifier);
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [`accept-${requestIdentifier}`]: true,
    }));

    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Driver/Accept/DispatchRequests/${requestIdentifier}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const resData = await res.json();
      if (!res.ok) {
        throw new Error(resData.message);
      }
      refetch();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [`accept-${requestIdentifier}`]: false,
      }));
      setAcceptRequestIdentifier(null);
    }
  };

  const handleReject = async (requestIdentifier) => {
    console.log("Sending reject request for identifier:", requestIdentifier);
  
    setRejectRequestIdentifier(requestIdentifier);
    setLoadingStates((prevLoadingStates) => ({
      ...prevLoadingStates,
      [`reject-${requestIdentifier}`]: true,
    }));
  
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Driver/Reject/DispatchRequests/${requestIdentifier}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log("Reject request response:", res);
  
      const resData = await res.json();
  
      if (!res.ok) {
        throw new Error(resData.message);
      }
  
      console.log("Reject request successful. Response data:", resData);
  
      refetch();
    } catch (error) {
      console.error("Error during reject request:", error);
      setError(error.message);
    } finally {
      setLoadingStates((prevLoadingStates) => ({
        ...prevLoadingStates,
        [`reject-${requestIdentifier}`]: false,
      }));
      setRejectRequestIdentifier(null);
    }
  };
  
  
  

  const filteredDispatches = Array.isArray(fetchedDispatches)
  ? fetchedDispatches.filter((dispatchItem) =>
      dispatchItem?.identifier.includes(searchId)
    )
  : [];

  return (
    <Fragment>
      {acceptRequestIdentifier && (
        <div className={`accept-overley ${acceptRequestIdentifier}`}>
          <div className="accept">
            <img
              src="https://i.ibb.co/cwrDm2r/box-tick-svgrepo-com-1-2x.png"
              alt=""
            />
            <h3>Accept Trip</h3>
            <p>Do you want to accept this Trip?</p>
            <span>
              <button onClick={() => setAcceptRequestIdentifier(null)}>
                NO
              </button>
              <button
                onClick={() => {
                  handleAccept(acceptRequestIdentifier);
                  setAcceptRequestIdentifier(null);
                }}
                disabled={loadingStates[`accept-${acceptRequestIdentifier}`]}
              >
                {loadingStates[`accept-${acceptRequestIdentifier}`] ? (
                  <Spinner />
                ) : (
                  "Accept"
                )}
              </button>
            </span>
          </div>
        </div>
      )}

      {rejectRequestIdentifier && (
        <div className={`reject-overley ${rejectRequestIdentifier}`}>
          <div className="reject">
            <img
              src="https://i.ibb.co/3TwpxZf/box-remove-svgrepo-com-1-2x.png"
              alt=""
            />
            <h3>Reject Trip</h3>
            <p>Do you want to decline this Trip?</p>
            <span>
              <button onClick={() => setRejectRequestIdentifier(null)}>
                NO
              </button>
              <button
                onClick={() => {
                  handleReject(rejectRequestIdentifier);
                  setRejectRequestIdentifier(null);
                }}
                disabled={loadingStates[`reject-${rejectRequestIdentifier}`]}
              >
                {loadingStates[`reject-${rejectRequestIdentifier}`] ? (
                  <Spinner />
                ) : (
                  "Reject"
                )}
              </button>
            </span>
          </div>
        </div>
      )}

      {error && <p>{fetchError}</p>}
      <div className="driver-home-container">
        <h4>Trips</h4>
        <div className="driver-dispatch">
          <div className="dispatch-req">
            <div className="dispatch-card">
              {fetchError && <p>{fetchError}</p>}
              <span>
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  type="search"
                  placeholder="Search by ID"
                  value={searchId}
                  onChange={(e) => setSearchId(e.target.value)}
                />
              </span>
              {filteredDispatches && filteredDispatches.length > 0 ? (
                filteredDispatches.map((dispatchItem) => (
                  <div className="card" key={dispatchItem.identifier}>
                    <h2>T{dispatchItem.identifier.slice(0, 12).toUpperCase()}</h2>
                    <p>Package: {dispatchItem.dispatch.package.name}</p>
                    <p>
                      Receiver Address 1:{" "}
                      {dispatchItem.dispatch.package.shipping_information.receiver
                        ?.address_1 || "N/A"}
                    </p>
                    <p>
                      Time:{" "}
                      {dispatchItem.dispatch.package.category.created_at}
                    </p>
                    <p>Status: {dispatchItem.status}</p>

                    <div className="btns">
                      <button
                        onClick={() =>
                          setAcceptRequestIdentifier(dispatchItem.identifier)
                        }
                        disabled={
                          dispatchItem.status === "assigned" ||
                          dispatchItem.status === "accepted" ||
                          dispatchItem.status === "rejected"
                        }
                        className={
                          dispatchItem.status === "assigned" ||
                          dispatchItem.status === "accepted" ||
                          dispatchItem.status === "rejected"
                            ? "disabled"
                            : ""
                        }
                      >
                        Accept
                      </button>
                      <button
                        onClick={() =>
                          setRejectRequestIdentifier(dispatchItem.identifier)
                        }
                        disabled={
                          dispatchItem.status === "assigned" ||
                          dispatchItem.status === "accepted" ||
                          dispatchItem.status === "rejected"
                        }
                        className={
                          dispatchItem.status === "assigned" ||
                          dispatchItem.status === "accepted" ||
                          dispatchItem.status === "rejected"
                            ? "disabled"
                            : ""
                        }
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <p>No dispatches found.</p>
              )}
            </div>
          </div>
          <UpdateDispatch />
        </div>
      </div>
    </Fragment>
  );
};

export default DriverHome;

