import {NavLink as Link, Outlet } from "react-router-dom";
import { useContext, Fragment } from "react";
import { AuthContext } from "../Context/context";
import DropDown from "../Pages/DropDown";
import "./Navbar.css";

const Navbar = () => {
  const { isMobile, setIsmobile, isDropDown } =
    useContext(AuthContext);

  // const toggleDropDown = () => {
  //   setIsDropDown(!isDropDown);
  // };

  const closeMobileMenu = () => {
    setIsmobile(false);
  };


  const NavLinkLCss = ({ isActive }) => {
    return {
      color: isActive ? "#294697" : "",
    };
  };

  return (
    <Fragment>
      <div className="nav-container">
        <div className="mobile-menu" onClick={() => setIsmobile(!isMobile)}>
          {isMobile ? (
            <i className="fa-solid fa-xmark"></i>
          ) : (
            <i className="fa-solid fa-bars"></i>
          )}
        </div>
        <Link to="/">
          <img src="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" alt="" />
        </Link>
        <div
          className={`nav-link ${isMobile ? "mobile-link active" : ""} ${
            isDropDown ? "active" : ""
          }`}
        >
          <Link to="/Services" onClick={closeMobileMenu} style={NavLinkLCss}>
            Services
          {/* <i className="fa-solid fa-angle-down" onClick={toggleDropDown}></i> */}
          </Link>
          {isDropDown && <DropDown closeMenu={closeMobileMenu} />}
          <Link to="/AboutUs" onClick={closeMobileMenu} style={NavLinkLCss}>
            About Us
          </Link>
          <Link to="/OurOffices" onClick={closeMobileMenu} style={NavLinkLCss}>
            Our Offices
          </Link>
          <Link to="/GetQuotes" onClick={closeMobileMenu} style={NavLinkLCss}>
            Get Quotes
          </Link>
          {/* <Link to="/ContactUs" onClick={closeMobileMenu} style={NavLinkLCss}>
          Contact Us
          </Link> */}
        </div>

          <Link to="/Login" onClick={closeMobileMenu} className="logs">
            Log in
          </Link>
      </div>
      <Outlet />
    </Fragment>
  );
};

export default Navbar;
