import React, { useState,  useContext } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../Context/context";
import "../Css/Inventory.css";

const InventoryDriver = () => {
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { token } = useContext(AuthContext);

  const {
    data: driverData,
    error: driversError,
    isLoading: driversLoading,
  } = useQuery(
    "drivers",
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Drivers`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (data.status === "success") {
        if (data.results.length > 0) {
          setSelectedDriver(data.results[0].identifier);
        }
        return data.results;
      } else {
        throw new Error("Error fetching driver data");
      }
    }
  );

  const {
    data: selectedDriverDetails,
    error: selectedDriverError,
    isLoading: selectedDriverLoading,
  } = useQuery(
    ["selectedDriver", selectedDriver],
    async () => {
      if (selectedDriver) {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Drivers/${selectedDriver}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (data.status === "success") {
          return data.results;
        } else {
          throw new Error("Error fetching selected driver data");
        }
      }
    },
    { enabled: Boolean(selectedDriver) }
  );

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredDrivers = driverData?.filter((driver) =>
    driver.full_name.toLowerCase().includes(searchQuery.toLowerCase())
  ) || [];

  return (
    <div className="inventory-driver">
      <div className="add-trips">
        <p>Driver's information</p>
      </div>
      <div className="wrapper">
        <div className="driver">
          <span>
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              type="search"
              placeholder="Search by name..."
              value={searchQuery}
              onChange={handleSearch}
            />
            <i className="fa-solid fa-sliders"></i>
          </span>
          <div className="logs-driver">
            {driversLoading && <p>Loading drivers...</p>}
            {driversError && <p>Error loading drivers: {driversError.message}</p>}
            {!driversLoading &&
              !driversError &&
              filteredDrivers.map((item) => (
                <li
                  key={item.identifier}
                  onClick={() => setSelectedDriver(item.identifier)}
                >
                  <span>
                    <p>{item.full_name}</p>
                    <h3>ID {item.identifier.slice(0, 12).replace(/-/g, '').toUpperCase()}</h3>
                  </span>
                </li>
              ))}
          </div>
        </div>
        <div className="edit-driver">
          {selectedDriverLoading && <p>Loading driver details...</p>}
          {selectedDriverError && (
            <p>Error loading selected driver details: {selectedDriverError.message}</p>
          )}
          {!selectedDriverLoading && !selectedDriverError && selectedDriverDetails && (
            <div className="card">
              <span>
                <p>Full Name</p>
                <h2>{selectedDriverDetails.full_name}</h2>
              </span>

              <span>
                <p>Email</p>
                <h2>{selectedDriverDetails.email}</h2>
              </span>

              <span>
                <p>Address</p>
                <h2>{selectedDriverDetails.address}</h2>
              </span>

              <span>
                <p>Driver's License Number</p>
                <h2>{selectedDriverDetails.drivers_license_number}</h2>
              </span>

              <span>
                <p>Vehicle Plate Number</p>
                <h2>{selectedDriverDetails.vehicle_plate_number}</h2>
              </span>

              <span>
                <p>Vehicle Registration Number</p>
                <h2>{selectedDriverDetails.vehicle_registration_number}</h2>
              </span>

              <span>
                <p>Vehicle Type</p>
                <h2>{selectedDriverDetails.vehicle_type}</h2>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryDriver;

