import React, { useState, useEffect, useContext, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../Context/context";
import "../Css/Dispatch.css";
import Spinner from "../Spinner/Spinner";

const DispatchDetails = () => {
  const { token } = useContext(AuthContext);
  const { dispatchIdentifier } = useParams();
  const [dispatchDetails, setDispatchDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [successfully, setSucces] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const fetchDispatchDetails = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Manager/Fetch/Dispatches/${dispatchIdentifier}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.message);
        }

        setDispatchDetails(data.results);
        // console.log(data);
      } catch (error) {
        console.error("Error fetching dispatch details:", error);
        setError("An error occurred while fetching dispatch details");
      } finally {
        setIsLoading(false);
      }
    };

    const fetchDrivers = async () => {
      try {
        const driversResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Drivers`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const driversData = await driversResponse.json();
        if (!driversResponse.ok) {
          throw new Error(driversData.message);
        }

        setDrivers(driversData.results);
      } catch (error) {
        console.error("Error fetching drivers:", error);
        setError("An error occurred while fetching drivers");
      }
    };

    fetchDispatchDetails();
    fetchDrivers();
  }, [dispatchIdentifier, token]);

  const handleDriverSelection = (driver) => {
    setSelectedDriver(driver);
  };

  const handleAssignDriver = async (e) => {
    e.preventDefault();
    if (!selectedDriver) {
      console.error("No driver selected");
      return;
    }
    setIsLoading(true);

    try {
      console.log("assigning a driver");
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Manager/Request/Dispatch`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            driver_identifier: selectedDriver.identifier,
            package_identifier: dispatchDetails.package.identifier,
          }),
        }
      );

      const resData = await response.json();

      if (!response.ok) {
        if (
          resData.message ===
          "Driver has already been requested to deliver this package"
        ) {
          console.log("Setting success message:", resData.message);
          setSucces(
            "Driver has already been requested to deliver this package"
          );
        } else {
          console.error("Unexpected response message:", resData.message);
          throw new Error(resData.message);
        }
      } else {
        setSucces("Make Dispatch Request Successful");
        console.log("Make Dispatch Request Successful");
      }
    } catch (error) {
      console.log(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (error) {
    return <p>{error}</p>;
  }

  if (!dispatchDetails) {
    return <p>No details found for the specified dispatch</p>;
  }
  const filteredDrivers = drivers.filter((driver) =>
    driver.full_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <Fragment>
      {successfully && (
        <div className="assign-success">
          <h2>{successfully}</h2>
          <button onClick={() => setSucces(false)}>OKAY</button>
        </div>
      )}

      <div className="dispatch-details">
        <div className="dispatch-header">
          <h1>Dedicated Dispatch</h1>
          <span>
            <i className="fa-solid fa-magnifying-glass"></i>
            <input type="search" placeholder="Search Order ID" />
          </span>
        </div>
        <div className="wrapper">
          <div className="inner">
            <span>
              <i
                className="fa-solid fa-arrow-left"
                onClick={() => navigate("/DedicatedDispatch")}
              ></i>
              <p>TID{dispatchDetails.identifier}</p>
            </span>
            <span>
              <p>{dispatchDetails.package.category.created_at}</p>
              <button onClick={() => setIsDriver(!isDriver)}>Process</button>
            </span>
          </div>
          <h2 style={{ padding: "20px" }}>
            {" "}
            Status: {dispatchDetails.package.dispatch.status}
          </h2>
          <div className="sender-details">
            <p>SENDER'S INFO</p>
            <div className="sender">
              <span>
                <p>
                  Senders Fullname <br />{" "}
                  {dispatchDetails.package?.shipping_information?.sender
                    ?.name || "N/A"}
                </p>
              </span>
              <span>
                <p>
                  State <br />{" "}
                  {dispatchDetails.package?.shipping_information?.sender?.state
                    ?.state_label || "N/A"}
                </p>
              </span>

              <span>
                <p>
                  Address Line 1 <br />{" "}
                  {dispatchDetails.package?.shipping_information?.sender
                    ?.address_1 || "N/A"}
                </p>
              </span>

              <span>
                <p>
                  Phone Number <br />{" "}
                  {dispatchDetails.package?.shipping_information?.sender
                    ?.phone_number || "N/A"}
                </p>
              </span>
            </div>
          </div>

          <div className="receiver-details">
            <p>RECEIVER'S INFO</p>
            <div className="receiver">
              <span>
                <p>
                  Receiver's Fullname <br />{" "}
                  {dispatchDetails?.package?.shipping_information?.receiver
                    ?.name || "N/A"}
                </p>
              </span>
              <span>
                <p>
                  State <br />{" "}
                  {dispatchDetails?.package?.shipping_information?.receiver
                    ?.state?.state_label || "N/A"}
                </p>
              </span>
              <span>
                <p>
                  Address Line 1 <br />{" "}
                  {dispatchDetails?.package?.shipping_information?.receiver
                    ?.address_1 || "N/A"}
                </p>
              </span>
              <span>
                <p>
                  Phone Number <br />{" "}
                  {dispatchDetails?.package?.shipping_information?.receiver
                    ?.phone_number || "N/A"}
                </p>
              </span>
            </div>
          </div>

          <div className="sender-details">
            <p>ITEM(S) INFO</p>
            <table>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Category</th>
                  <th>Item</th>
                  <th>Weight</th>
                  <th>Quantity</th>
                  <th>Valued at</th>
                  <th>Shipping Fee</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{dispatchDetails.package?.image || "N/A"}</td>
                  <td>{dispatchDetails.package?.category?.name || "N/A"}</td>
                  <td>{dispatchDetails.package?.name || "N/A"}</td>
                  <td>{dispatchDetails.package?.weight || "N/A"}</td>
                  <td>{dispatchDetails.package?.quantity || "N/A"}</td>
                  <td>
                    &#8358;{" "}
                    {Number(dispatchDetails.package?.value).toLocaleString() ||
                      "N/A"}
                  </td>
                  <td>
                    &#8358;{" "}
                    {Number(
                      dispatchDetails.package?.shipping_cost
                    ).toLocaleString() || "N/A"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {isDriver && (
          <div className="available-driver">
            <i
              className="fa-solid fa-arrow-left"
              onClick={() => setIsDriver(false)}
            ></i>
            <div className="wrapping">
              <div className="driver-list">
                <h2>Driver List</h2>
                <div className="header">
                  <span>
                    <i className="fa-solid fa-magnifying-glass"></i>
                    <input
                      type="search"
                      placeholder="Search Driver by Name"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </span>
                </div>
                <div className="driver-scroll">
                  {filteredDrivers.map((driver) => (
                    <li
                      key={driver.identifier}
                      onClick={() => handleDriverSelection(driver)}
                    >
                      {driver.full_name}
                    </li>
                  ))}
                </div>
              </div>

              <div className="selected-driver-info">
                <p>Driver Information:</p>
                <p>Name: {selectedDriver && selectedDriver.full_name}</p>
                <p>
                  Driver License:{" "}
                  {selectedDriver && selectedDriver.drivers_license_number}
                </p>
                <p>Email: {selectedDriver && selectedDriver.email}</p>
                <p>
                  Vehicle Model: {selectedDriver && selectedDriver.vehicle_type}
                </p>
                <p>
                  Vehicle Plate Number:{" "}
                  {selectedDriver && selectedDriver.vehicle_registration_number}
                </p>
                <button onClick={handleAssignDriver}>
                  {isLoading ? <Spinner /> : "Assign Driver"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default DispatchDetails;
