import { useContext, useState } from "react";
import { AuthContext } from "../Context/context";
import "../Css/Settings.css";
import Profile from "../SupperAdmin/Profile";

const Settings = () => {
  const { LogOut } = useContext(AuthContext);
  const [isProfile, setIsProfile] = useState(true);

  const handlePro = () => {
    setIsProfile(true);
  };
  return (
    <div className="setting-container">
      <div className="side-nav">
        <span>
          <i className="fa-solid fa-user"></i>
          <p onClick={handlePro}>My Profile</p>
        </span>
        <span>
          <i className="fa-solid fa-right-from-bracket"></i>
          <p onClick={() => LogOut()}>Log Out</p>
        </span>
      </div>

      <div className="switch-container">{isProfile && <Profile />}</div>
    </div>
  );
};

export default Settings;
