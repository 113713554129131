import { Link } from "react-router-dom";
import "../Css/Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="wrapperss">
        <div className="fot-logo">
        <img src="https://i.ibb.co/tZqx2TG/Layer-1-2x.png" alt="" />
        </div>
       

        <a href="https://arveety.com/" target="blank">
        
          <p>&copy; 2023 Company. All Right reserved.</p>
          <p>Designed by Arveety Limited</p>
        </a>

        <div className="social-container">
          <div className="social">
            <Link to='/AboutUs'>About Us |</Link>
            <Link>Blog |</Link>
            <Link>Privacy & Terms |</Link>
            <Link>Contacts </Link>
          </div>
          <div className="social-icon">
            <a href="https://twitter.com/doorstep_ng?lang=en" target="blank"> <i className="fa-brands fa-twitter"></i></a>
           <a href="https://web.facebook.com/doorstepdispatch/?_rdc=1&_rdr" target="blank"><i className="fa-brands fa-facebook"></i></a>
           <a href="https://www.instagram.com/doorstep_ng?igsh=ZjAyMmdwZWRhNTV0" target="blank"> <i className="fa-brands fa-instagram"></i></a>
            <a href="https://www.linkedin.com/company/doorsteplogistics/" target="blank"> <i className="fa-brands fa-linkedin"></i></a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
