import { useState, useContext } from "react";
import { AuthContext } from "../Context/context";

const useFormValidationAddDrivers = () => {
  const defaultFormData = {
    first_name: "",
    last_name: "",
    email: "",
    country_code: "234",
    phone_number: "",
    address: "",
    vehicle_type: "",
    vehicle_plate_number: "",
    vehicle_registration_number: "",
    drivers_license_number: "",
  };
  
  

  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [resDataError, setResDataError] = useState(null);
  const { token } = useContext(AuthContext);
  const [successMessage, setSuccessMessage] = useState(null);

  const handleSuccess = (message) => {
    
    setSuccessMessage(message);
    setTimeout(() => {
      setSuccessMessage(null);
    }, 5000); 
  };

  const resetForm = () => {
    setFormData(defaultFormData);
  };

  const validateNotEmpty = (value) => {
    return value.trim() !== "";
  };

  const validateForm = () => {
    const newErrors = {};

    for (const field in formData) {
      if (!validateNotEmpty(formData[field])) {
        newErrors[field] = "This field cannot be empty";
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    console.log("Changing input", e.target.name, e.target.value);
    setErrors({});
    setResDataError(null);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      setIsLoading(true);

      const formDataToSend = { ...formData };
      console.log(formDataToSend)

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Create/Driver/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formDataToSend),
          }
        );

        const resData = await response.json();

        if (!response.ok) {
          setResDataError(resData.message);
          throw new Error(resData.message);
        }

        handleSuccess(resData.message);
        resetForm();

      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    formData,
    errors,
    isLoading,
    handleInputChange,
    resDataError,
    handleSubmit,
    successMessage
  };
};

export default useFormValidationAddDrivers;
