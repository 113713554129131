import { useNavigate } from "react-router-dom";
import React from "react";
import useFormValidation from "../Hooks/formHook";
import Spinner from "../Spinner/Spinner";
import "../Css/Auth.css";

const AddOfficers = () => {
  const {
    formData,
    errors,
    handleInputChange,
    handleSubmit,
    isLoading,
    resDataError,
    successMessage
  } = useFormValidation();
  const navigate = useNavigate();
  return (
    <div className="add-officer-page">
      <span>
        <i
          className="fa-solid fa-arrow-left"
          onClick={() => navigate("/Logistics")}
        ></i>
        <p>Add Officer</p>
      </span>
      <div className="add">
        <div className="auth-container">
          <h2>CREATE AN ACCOUNT</h2>
          <form onSubmit={handleSubmit}>
            <div className="name-card">
              <label>
                First Name
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleInputChange}
                />
                {errors.first_name && (
                  <p className="error">{errors.first_name}</p>
                )}
              </label>
              <label>
                Last Name
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleInputChange}
                />
                {errors.last_name && (
                  <p className="error">{errors.last_name}</p>
                )}
              </label>
            </div>
            <div className="others-card">
              <label>
                Email Address
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </label>

              <label>
                Address
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                />
                {errors.address && <p className="error">{errors.address}</p>}
              </label>

              <label>
                Phone Number
                <span>
                  <select
                    name="country_code"
                    value={formData.country_code || "+1234"}
                    onChange={handleInputChange}
                  >
                    <option value="+1234">+234</option>
                  </select>
                  <input
                    type="text"
                    name="phone_number"
                    value={formData.phone_number}
                    onChange={handleInputChange}
                  />
                </span>
                {errors.phone_number && (
                  <p className="error">{errors.phone_number}</p>
                )}
              </label>
            </div>
            {resDataError && (
              <span style={{ margin: "auto", color: "red" }}>
                {resDataError}
              </span>
            )}
            {successMessage && <p style={{fontFamily:"Montserrat", margin:"auto", color:"green"}}>{successMessage}</p>}
            <button type="submit" disabled={isLoading}>
              {isLoading ? <Spinner /> : "Send code"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddOfficers;
