import { useState, useContext } from "react";
import { AuthContext } from "../Context/context";

const useFormValidation = () => {
  const { token } = useContext(AuthContext);
  const defaultFormData = {
    email: "",
    phone_number: "",
    first_name: "",
    last_name: "",
    country_code: "234",
    address: "",
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [resDataError, setResDataError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const validateFirstName = (first_name) => {
    return first_name.trim() !== "" ? null : "First name is required";
  };

  const validateLastName = (last_name) => {
    return last_name.trim() !== "" ? null : "Last name is required";
  };

  const validateAddress = (address) => {
    return address.trim() !== "" ? true : "Address is required";
  };

  const validatePhoneNumber = (phone_number) => {
    return phone_number.trim() !== "" ? null : "Phone number is required";
  };

  const handleInputChange = (e) => {
    setErrors({});
    setResDataError(null);
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!validateEmail(formData.email)) {
      newErrors.email = "Invalid email address";
    }

    const firstNameError = validateFirstName(formData.first_name);
    if (firstNameError) {
      newErrors.first_name = firstNameError;
    }

    const lastNameError = validateLastName(formData.last_name);
    if (lastNameError) {
      newErrors.last_name = lastNameError;
    }

    const addressError = !validateAddress(formData.address);
    if (addressError) {
      newErrors.address = "Address is required";
    }

    const phoneNumberError = validatePhoneNumber(formData.phone_number);
    if (phoneNumberError) {
      newErrors.phone_number = phoneNumberError;
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const data = {
        email: formData.email,
        phone_number: formData.phone_number,
        first_name: formData.first_name,
        last_name: formData.last_name,
        country_code: formData.country_code,
        address: formData.address,
      };

      setIsLoading(true);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Create/InventoryManager/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          }
        );

        const resData = await response.json();


        if (!response.ok) {
          setResDataError(resData.message);
          throw new Error(resData.message);
        }

        setFormData(defaultFormData);
        setSuccessMessage("User created successfully!");
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return {
    formData,
    errors,
    isLoading,
    handleInputChange,
    handleSubmit,
    resDataError,
    successMessage,
  };
};

export default useFormValidation;
