// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import "./caroussel.css"

// const images = [
//     'https://i.ibb.co/5vVTwgx/Airliner-I05-1-2x.png',
//     'https://i.ibb.co/ZBsd1XH/Cardboard-Box-New-J04-2x.png',
//     'https://i.ibb.co/d5gkb9F/Container-Ship-I13-2-2x.png',
//     'https://i.ibb.co/qB69X20/Container-Truck-I05-22x.png',
//   ];

// const settings = {
//   dots: false,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 3000,
//   nextArrow: false,
//   prevArrow: false,
// };

// const Carousel = () => {
//   return (
//     <div className='slider-co'>
//     <Slider {...settings}>
//       {images.map((image, index) => (
//           <div key={index} >
//           <img src={image} alt={`slide ${index}`} />
//         </div>
//       ))}
//     </Slider>
//       </div>
//   );
// };

// export default Carousel;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./caroussel.css";

const images = [
  "https://i.ibb.co/5vVTwgx/Airliner-I05-1-2x.png",
  "https://i.ibb.co/GnqPn11/Box-copy-2-2x.png",
  "https://i.ibb.co/d5gkb9F/Container-Ship-I13-2-2x.png",
  "https://i.ibb.co/8cx9Wb3/Container-Truck-I05-1-2x.png"
];

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  nextArrow: false,
  prevArrow: false,
};

const Carousel = () => {
  return (
    <div className="slider-co">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`custom-slide ${index === 1 ? "cardboard-slide" : ""}`}
          >
            <img src={image} alt={`slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
