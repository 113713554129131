import React, { useContext, useState } from "react";
import { useQuery } from "react-query";
import { AuthContext } from "../Context/context";
import "../Css/super.css";

const OverView = () => {
  const { token } = useContext(AuthContext);
  const [metrics, setMetrics] = useState([]);

  const fetchMetrics = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/Interface/V1/Fetch/Metrics`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message);
    }

    setMetrics(data.results);
    console.log(data.results)
    return data.results;
  };
  const {
    error,
  } = useQuery("metrics", fetchMetrics);
  

  return (
    <div className="over-view-container">
      <div className="over-header">
        <span>
          <h3>Overview</h3>
          <p>Welcome back, <span>Super admin</span></p>
        </span>
      </div>

      <div className="metrics-box">
        <div className="box">
          <h3>Total packages</h3>
          <p>Total : {metrics?.packages}</p>
          <p>Active : {metrics?.active_packages}</p>
          <p>Delivered : {metrics?.delivered_packages}</p>
          <p>Failed : {metrics?.failed_packages}</p>
        </div>
        <div className="box">
          <h3>Total Drivers</h3>
          <p>Tatal : {metrics?.drivers}</p>
          <p>Suspended : {metrics?.suspended_drivers}</p>
        </div>

        <div className="box">
          <h3>Total Managers</h3>
          <p>Total : {metrics?.inventory_managers}</p>
          <p>Suspended : {metrics?.suspended_inventory_managers}</p>
        </div>
      </div>
      {error && <p>Error: {error.message}</p>}
      
    </div>
  );
};

export default OverView;
