import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useContext, Fragment } from "react";
import { AuthContext } from "../Context/context";
import useFormValidationLogin from "../Hooks/LoginHook";
import Spinner from "../Spinner/Spinner";
import "../Css/Auth.css";

const Login = () => {
  const { isPassWord, setIsPassword} =
    useContext(AuthContext);
  const {
    formData,
    errors,
    handleInputChange,
    handleSubmit,
    isLoading,
    resDataError,
  } = useFormValidationLogin();
  const handlePasswords = () => {
    setIsPassword(!isPassWord);
  };

  return (
    <Fragment>
       <Helmet>
      <title>Login | Doorstep</title>
        <meta name="description" content="Login page of Doorstep, your trusted logistics partner." />
        <link rel="icon" href="/public/favicon.ico" />
       
  <meta property="og:type" content="website" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:url" content="https://www.doorstepng.com/" />

  
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta name="twitter:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta name="twitter:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="instapp:site_name" content="Doorstep" />
  <meta property="instapp:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="instapp:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="instapp:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />

  
  <meta property="tiktok:card" content="summary_large_image" />
  <meta property="tiktok:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="tiktok:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="tiktok:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />


  <meta property="og:image" content="https://i.ibb.co/r7M3jT5/Layer-1-2x-1.png" />
  <meta property="og:title" content="Doorstep - Your Trusted Logistics Partner" />
  <meta property="og:description" content="We handle all the details, from pickup to delivery, so you can focus on your business." />
  <meta property="og:url" content="https://www.doorstepng.com/" />
      </Helmet>
   
    <div className="auth-container">
      <h2>Welcome Back</h2>
      <form onSubmit={handleSubmit}>
        <div className="others-card">
          <label>
            Email Address
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
            {errors.email && <p className="error">{errors.email}</p>}
          </label>
          <label>
            Password
            <span>
              <input
                type={isPassWord ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
              />
              <i
                className="fa-solid fa-eye-slash"
                onClick={handlePasswords}
              ></i>
            </span>
            <div className="forgot">
              <Link to="/ForgotPassword" className="link">
                Forgot Password?
              </Link>
            </div>
          </label>
        </div>
        {errors.password && (
          <p className="error">
            {errors.password.map((error, index) => (
              <span style={{  color: "red", fontFamily:"Montserrat" }} key={index}>{error}</span>
            ))}
          </p>
        )}

        {resDataError && (
          <span style={{ margin: "auto", color: "red", fontFamily:"Montserrat" }}>{resDataError}</span>
        )}

        <button>{isLoading ? <Spinner /> : "Log in"}</button>
      </form>
    </div>
    </Fragment>
  );
};

export default Login;
