
import React, { createContext, useCallback, useState, useEffect } from "react";


export const ShippingContext = createContext({
  senderInfo: {},
  receiverInfo: {},
  setSenderInfo: () => {},
  setReceiverInfo: () => {},
  saveShippingInfo: () => {},
  itemsInfo: [],
  setItemsInfo: () => {},
  saveItemsInfo: () => {},
  image: null,
  insuranceType: "",
  setInsuranceType: () => {},
  IsDrop: false,
    setIsDrop: () => {},
  error: false,
  setError: () => {},
  destination: "",
  setDestination: () => {},
  setSendersStateIdentifier: () => {},
  setReceiversStateIdentifier: () => {},
});

export const ShippingProvider = ({ children }) => {
  const [image, setImage] = useState(null)
  const [IsDrop, setIsDrop] = useState(false);
  const [insuranceType, setInsuranceType] = useState("");
  const [error, setError] = useState(false);
  const [destination, setDestination] = useState("");
  const [itemsInfo, setItemsInfo] = useState([]);
  const [quantity] = useState("");
  const [weight] = useState("");
  const [values] = useState("");
  const [name] = useState("");
  const [items, setItems] = useState("");
  const [sendersStateIdentifier, setSendersStateIdentifier] = useState(null);
  const [receiversStateIdentifier, setReceiversStateIdentifier] = useState(null);

  const handleItemInfoChange = (e) => {
    const { name, value } = e.target;
    setItemsInfo((prevItemsInfo) => ({
      ...prevItemsInfo,
      [name]: value,
    }));
  };

  const setItemsInfoData = useCallback(
    (data) => {
      setItemsInfo({ ...itemsInfo, ...data });
    },
    [itemsInfo]
  );

  const saveItemsInfo = useCallback(
    (data) => {
      const updatedItemsInfo = {
        ...itemsInfo,
        ...data,
      };

      localStorage.setItem("itemsInfo", JSON.stringify(updatedItemsInfo));
    },
    [itemsInfo]
  );

  const handleInsuranceTypeChange = (newInsuranceType) => {
    setInsuranceType(newInsuranceType);
  };

  const handleDestinationChange = (newDestination) => {
    setDestination(newDestination);
  };

  const handleDrop = () => {
    setIsDrop(!IsDrop);
  };

  const handleImage = (file) => {
    setImage(file);
    setError(null);
  };

  const savedShippingInfo = JSON.parse(localStorage.getItem("shippingInfo"));
  const [senderInfo, setSenderInfo] = useState(
    savedShippingInfo?.senderInfo || {
      senders_name: "",
      senders_phone_number: "",
      senders_email: "",
      senders_address_1: "",
      senders_address_2: "",
      senders_state: "",
      senders_zip_code: "",
      senders_state_identifier: "",
    }
  );

  const [receiverInfo, setReceiverInfo] = useState(
    savedShippingInfo?.receiverInfo || {
      receivers_name: "",
      receivers_phone_number: "",
      receivers_email: "",
      receivers_address_1: "",
      receivers_address_2: "",
      receivers_state: "",
      receivers_zip_code: "",
      receivers_state_identifier: "",
    }
  );

  const setSenderInfoData = useCallback(
    (data) => {
      setSenderInfo({ ...senderInfo, ...data });
    },
    [senderInfo]
  );

  const setReceiverInfoData = useCallback(
    (data) => {
      setReceiverInfo({ ...receiverInfo, ...data });
    },
    [receiverInfo]
  );

  const saveShippingInfo = useCallback(
    (data) => {
      const shippingInfo = {
        senderInfo,
        receiverInfo,
      };

      localStorage.setItem("shippingInfo", JSON.stringify(shippingInfo));
    },
    [receiverInfo, senderInfo]
  );

  
  const value = {
    senderInfo,
    receiverInfo,
    setSenderInfo: setSenderInfoData,
    setReceiverInfo: setReceiverInfoData,
    saveShippingInfo,
    quantity,
    weight,
    values,
    setItems,
    items,
    handleImage,
    image,
    setError,
    error,
    name,
    handleDrop,
    IsDrop,
    setIsDrop,
    destination,
    setDestination: handleDestinationChange,
    insuranceType,
    setInsuranceType: handleInsuranceTypeChange,
    itemsInfo,
    setItemsInfo: setItemsInfoData,
    saveItemsInfo,
    handleItemInfoChange,
    setSendersStateIdentifier,
    sendersStateIdentifier,
    setReceiversStateIdentifier,
    receiversStateIdentifier,
  };

  useEffect(() => {
    const storedItemsInfo = JSON.parse(localStorage.getItem("itemsInfo"));

    if (storedItemsInfo) {
      setItemsInfo(storedItemsInfo);
      setInsuranceType(storedItemsInfo.insuranceType);
    }
  }, []);

  return (
    <ShippingContext.Provider value={value}>
      {children}
    </ShippingContext.Provider>
  );
};

